import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';

import { AppRoutes } from '../routes';
import Home from './home';
import {
  Login,
  Signup,
  ResetStart,
  ChangePassword,
  VerifyFormResetPassword,
  ResetPassword,
} from '../auth';
import About from './about';
import Arbitration from './arbitration';
import Contact from './contact';
import { TermsAndConditions } from './terms';

export default function PublicRoutes() {
  return (
    <Switch>
      <Route path={AppRoutes.HOME} exact component={Home} />
      <Route path={AppRoutes.ABOUT} exact component={About} />
      <Route path={AppRoutes.ARBITRATION} exact component={Arbitration} />
      <Route path={AppRoutes.CONTACT} exact component={Contact} />
      <Route path={AppRoutes.TERMS} exact component={TermsAndConditions} />
      <Route path={AppRoutes.LOGIN} exact component={Login} />
      <Route path={AppRoutes.SIGNUP} exact component={Signup} />
      <Route path={AppRoutes.RESET_PASSWORD} exact component={ResetStart} />
      <Route path={AppRoutes.CHANGE_PASSWORD} exact component={ChangePassword} />
      <Route path={AppRoutes.VERIFYRESTPASWORD} exact component={VerifyFormResetPassword} />
      <Route path={AppRoutes.RESET_PASSWORD_FORM} exact component={ResetPassword} />
    </Switch>
  );
}
