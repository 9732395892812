import { Badge, chakra } from '@chakra-ui/react';
import React from 'react';

export const getStatusColor = (status: string) => {
  return {
    created: 'blue',
    completed: 'teal',
    error: 'red',
    cancelled: 'gray',
    approved: 'pigment-indigo',
    initiated: 'gray',
    collected: 'orange',
    success: 'eucalyptus',
    failed: 'red',
    arbitration: 'pink',
    paused: 'swamp-green',
    pending: 'eastern-blue',
  }[status];
};

export const ContractStatus = ({ status }: any) => {
  if (!status) return null;

  return (
    <div>
      <Badge className="rounded-sm" colorScheme={getStatusColor(status.toLowerCase())}>
        {status}
      </Badge>
    </div>
  );
};

export const LBadge = ({ dark, children }: any) => {
  return (
    <chakra.span
      className="rounded-full"
      px="2"
      py="0.5"
      bg={dark ? 'black' : 'white'}
      color={dark ? 'white' : 'black'}
      borderWidth="1px"
      borderColor={dark ? 'white' : 'black'}
      fontSize="sm">
      {children}
    </chakra.span>
  );
};
