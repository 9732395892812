import { Button, HStack, Textarea, useDisclosure } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { LButton } from './buttons';
import { PaymentCreateParams } from 'domain/repositories/payment';
import useArbitrationStore from 'app/state/store/arbitration';

export const RaiseArbitration = ({ contract }: { contract: PaymentCreateParams }) => {
  const [reason, setReason] = useState<any>();
  const { raiseArbitration, loading } = useArbitrationStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleRaiseArbitration = () => {
    raiseArbitration({ message: reason, transaction_id: contract.uuid, attachments: [] });
    onClose();
  };

  return (
    <div>
      <HStack spacing={2} m="2">
        <Button
          isLoading={loading}
          as={LButton}
          dark
          btnSize="sm"
          _focus={{ boxShadow: 'none', outline: 'none' }}
          onClick={onOpen}
          size="sm"
          colorScheme="red">
          Raise Arbitration
        </Button>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provide a reason for the Raising this arbitration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              variant="outline"
              type="text"
              name="reason"
              focusBorderColor="black"
              placeholder="Reason"
              onInput={(e: any) => setReason(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button as={LButton} dark onClick={handleRaiseArbitration}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
