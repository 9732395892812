import { Button, HStack, Textarea, useDisclosure } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { LButton } from '.';
import useTransactionStore from 'app/state/store/transactions';
import { PaymentCreateParams } from 'domain/repositories/payment';

type Props = {
  contract: PaymentCreateParams;
};
export const ApproveOrCancelButtons = ({ contract }: Props) => {
  const [reason, setReason] = useState<any>();
  const { approvePayment, cancelPayment, loading } = useTransactionStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCancel = () => {
    cancelPayment({ transaction_id: contract.uuid, reason });
    onClose();
  };

  return (
    <>
      <HStack spacing={2} m="2">
        <Button
          isLoading={loading}
          as={LButton}
          btnSize="sm"
          _focus={{ boxShadow: 'none', outline: 'none' }}
          onClick={() => approvePayment({ transaction_id: contract.uuid })}
          size="sm"
          colorScheme="green">
          Complete
        </Button>
        <Button
          isLoading={loading}
          as={LButton}
          dark
          btnSize="sm"
          _focus={{ boxShadow: 'none', outline: 'none' }}
          onClick={onOpen}
          size="sm"
          colorScheme="red">
          Cancel
        </Button>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provide a reason for the cancellation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              variant="outline"
              type="text"
              name="reason"
              focusBorderColor="black"
              placeholder="Reason"
              onInput={(e: any) => setReason(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button as={LButton} dark onClick={handleCancel}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
