import Axios from 'axios';
import { Environment } from 'env';
import { makeCookieStorageAdapter } from 'factory/cache';

const cookies = makeCookieStorageAdapter();
const token = cookies.get('session');

const headers: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Token ${Environment.API_TOKEN}`,
};

if (token) {
  headers['Authorization'] = `Bearer ${token}`;
}
console.log('the token is:', token);

export const api = Axios.create({
  baseURL: Environment.API_URL,
  headers,
});

export default api;
