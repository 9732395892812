import { Tabs, TabList, Tab, TabPanels, TabPanel, Text } from '@chakra-ui/react';
import React from 'react';

type DataProps = {
  label: string;
  content: React.ReactNode;
};
type Props = {
  data: DataProps[];
};

type DataTabProps = {
  children: React.ReactNode;
};

export const DataTab = ({ children }: DataTabProps) => (
  <Tab
    _focus={{ boxShadow: 'none', outline: 'none' }}
    borderBottom="2px"
    opacity="0.7"
    borderBottomColor="transparent"
    _selected={{ borderBottomColor: 'limbo.secondary', color: 'limbo.secondary', opacity: '1' }}>
    {children}
  </Tab>
);

export default function DataTabs({ data }: Props) {
  return (
    <Tabs variant="unstyled" isLazy>
      <TabList>
        {data.map((tab, index) => (
          <Tab
            key={index}
            _focus={{ boxShadow: 'none', outline: 'none' }}
            borderBottom="2px"
            opacity="0.7"
            borderBottomColor="transparent"
            _selected={{
              borderBottomColor: 'limbo.secondary',
              color: 'limbo.secondary',
              opacity: '1',
            }}>
            <Text fontSize={['0.85rem', 'md']} className="font-semibold ml-2">
              {tab.label}
            </Text>
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {data.map((tab, index) => (
          <TabPanel p={4} key={index}>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
