import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Tooltip,
  Collapse,
  Alert,
  AlertIcon,
  Text,
  Divider,
  Image,
  IconButton,
  useBreakpointValue,
  StackDirection,
  InputGroup,
  InputRightAddon,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { LButton } from '../../widgets/buttons';
import { Feature } from '../../controls';
import { ArrowDownIcon, ArrowLeftIcon, ArrowUpIcon, QuestionIcon } from '@chakra-ui/icons';
import { getPhoneNumber } from 'app/utils/regex';
import { AirtelLogo, LimboHalfLogo, MasterCardLogo, MTNLogo, VisaLogo } from 'app/images';
import { CardContent, Property } from 'app/components/widgets/cards';
import { useFormStore } from 'app/state/store';
import { handleError, handleSuccess, useProfiles } from 'app/hooks/backend';
import { PhonenumberInput } from '../fields/phone';
import useAuthStore from 'app/state/store/auth';
import _ from 'lodash';
import useTransactionStore from 'app/state/store/transactions';
import { MakePayment } from 'domain/repositories/payment';
import { UserProfile } from 'domain/repositories';
import { useFormErrors } from '../utils';

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

interface CreateContractFormProps {
  onComplete?: Function;
  initialContractType?: string;
}

interface FormData {
  amount: number;
  narration: string;
  period: number;
  receiver: string;
  category: string;
  payer: string;
}

export default function PurchaseForm({ onComplete }: CreateContractFormProps) {
  const [step, setStep] = useState('details');
  const { makePayment, loading } = useTransactionStore();

  const methods = useForm<FormData>({
    defaultValues: {
      amount: 0,
      narration: '',
      period: 0,
      receiver: '',
      category: '',
    },
  });
  const { form, setForm } = useFormStore();
  const { handleSubmit, reset: resetForm, getValues, errors } = methods;
  useFormErrors(errors);

  const onSubmit = handleSubmit(async values => {
    const { amount, narration, period, receiver } = values;
    const data: MakePayment = {
      receiver_account: receiver,
      narration,
      amount: amount,
      period: period,
      platform: 'web',
    };
    const response = await makePayment(data);
    if (response.data.status === 200) {
      handleSuccess('Congratulations Your Payment was sent successfully');
      resetForm();
      onComplete && (await onComplete());
    } else {
      handleError(response.data.message || 'Payment is unsuccessful Please try again later');
    }
  });

  useEffect(() => {
    const values = getValues();
    if (values) {
      setForm({ ...form, ...values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Collapse in={step === 'details'} animateOpacity>
          <ContractDetails />
          <Button
            mt="4"
            as={LButton}
            dark
            size="md"
            fontSize="md"
            onClick={async e => {
              e.preventDefault();
              const values = getValues();
              if (values.narration && values.period && values.receiver) {
                setStep('confirm');
              } else {
                handleError('Please fill in all required fields in a valid way');
              }
            }}
            w="50%">
            Next
          </Button>
        </Collapse>
        <Collapse in={step === 'confirm'} animateOpacity>
          <IconButton
            mb="1.5"
            size="xs"
            aria-label="back"
            icon={<ArrowLeftIcon />}
            onClick={() => setStep('details')}
          />
          <ConfirmDetails isSubmitting={loading} />
        </Collapse>
      </form>
    </FormProvider>
  );
}

const ContractDetails = () => {
  const { register, setValue, watch, control } = useFormContext();
  const [userPhone, setUserPhone] = useState<any>('');
  const { getSingleUser } = useAuthStore();
  //getting user name
  const [user, setUser] = useState<UserProfile | null>();
  useEffect(() => {
    const fetchUser = async () => {
      if (_.size(userPhone) >= 9) {
        const userPayerProfile = getSingleUser(userPhone);
        setUser(userPayerProfile);
      }
    };
    fetchUser();
    //reset when the component unmounts
    return () => {
      setUser(null);
    };
  }, [userPhone, getSingleUser]);

  const period = watch('period');

  return (
    <>
      <Stack spacing="6" mb="6">
        <HStack spacing="4">
          <FormControl>
            <FormLabel className="flex items-center space-x-2">
              <span>Who are you paying?</span>
              <Tooltip label="Provide phone number of receiver">
                <QuestionIcon />
              </Tooltip>
            </FormLabel>
            <PhonenumberInput
              name="receiver"
              control={control}
              onInput={(e: any) => {
                setUserPhone(getPhoneNumber(e.target.value));
                setValue('receiver', e.target.value);
              }}
            />
            <Collapse in={!!user} animateOpacity>
              <Alert status="info" rounded="sm" fontSize="sm" p="2" mt="2">
                <AlertIcon boxSize="16px" mr="1.5" />
                {user && user?.first_name + ' ' + user?.last_name}
              </Alert>
            </Collapse>
          </FormControl>
        </HStack>
        <FormControl>
          <FormLabel>Purpose</FormLabel>
          <Input
            focusBorderColor="black"
            name="narration"
            type="text"
            required
            ref={register}
            onInput={(e: any) => {
              setValue('narration', e.target.value);
            }}
          />
        </FormControl>
        <HStack spacing="4" display="flex" alignItems="flex-start">
          <FormControl>
            <FormLabel>Period</FormLabel>
            <Stack spacing="4">
              <InputGroup>
                <Input
                  focusBorderColor="black"
                  name="period"
                  type="number"
                  required
                  ref={register}
                />
                <InputRightAddon children="Hours" />
              </InputGroup>
            </Stack>
          </FormControl>
        </HStack>
      </Stack>
      <Flex direction="row-reverse">
        <span className="text-xs">
          <p className="text-gray-500 mb-2">Due Date</p>
          <p className="font-black md:font-semibold">
            {period && period > 0
              ? dayjs().add(period, 'hour').format('DD MMM, YYYY HH:mm')
              : 'No date'}
          </p>
        </span>
      </Flex>
    </>
  );
};

const ConfirmDetails = ({ isSubmitting }: any) => {
  const [isLessThan1280] = useMediaQuery('(max-width: 1280px)');
  const direction = useBreakpointValue({ base: 'column', md: 'row' }) as StackDirection;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [showDetails, setShowDetails] = useState(true);

  const { getValues, register, control } = useFormContext();
  const [amountInput, setAmount] = useState<any>();
  const [totalAmount, setTotalAmount] = useState(0);
  const { form, setForm } = useFormStore();
  const { profile } = useProfiles();

  useEffect(() => {
    if (isMobile) {
      setShowDetails(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    const total = Number(amountInput);

    setTotalAmount(total);
    setForm({ ...form, amount: total });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountInput]);

  const { getSingleUser } = useAuthStore();
  //getting user name
  const [user, setUser] = useState<UserProfile | null>();
  useEffect(() => {
    const fetchUser = async () => {
      // const userPayerProfile = getSingleUser(form.receiver);
      const userPayerProfile = getSingleUser(form.receiver);
      setUser(userPayerProfile);
    };
    fetchUser();
  }, [form.receiver, getSingleUser]);

  const getPeriod = () => {
    return form.period + ' hours';
  };

  return (
    <>
      <HStack spacing="2" mb="4">
        <Image src={VisaLogo} alt="visa" />
        <Image src={MasterCardLogo} alt="mastercard" />
        <Image src={AirtelLogo} alt="airtel" />
        <Image
          src={MTNLogo}
          viewBox="0 0 87.054 22.561"
          width="87.054px"
          height="22.561px"
          alt="mtn"
        />
      </HStack>
      <Stack
        fontSize={['smaller', 'md']}
        direction={direction}
        spacing="6"
        mb="6"
        alignItems="start"
        w="full">
        <Stack spacing="4" w={['full', '50%']} fontSize="sm">
          <FormControl>
            <FormLabel className="flex items-center space-x-2">
              <span>Amount</span>
            </FormLabel>
            <Input
              name="amount"
              ref={register}
              required
              focusBorderColor="black"
              type="number"
              onInput={(e: any) => setAmount(e.target.value)}
              placeholder="How much are you paying?"
            />
          </FormControl>
          <Feature on={form.payment_channel === 'mobilemoney'}>
            <FormControl>
              <FormLabel className="flex items-center space-x-2">
                <span>Which phone are you using to pay?</span>
                <Tooltip label="Provide phone number to pay from">
                  <QuestionIcon />
                </Tooltip>
              </FormLabel>
              <PhonenumberInput defaultValue={profile?.username} name="payer" control={control} />
            </FormControl>
          </Feature>
          <span
            className="cursor-pointer px-2 py-1 bg-gray-200 rounded-full w-2/5 inline-flex justify-center items-center"
            hidden={!isMobile}
            onClick={() => setShowDetails(!showDetails)}>
            <span>View Details</span>
            {showDetails ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </span>
          <Collapse in={!!user && showDetails} animateOpacity>
            <CardContent>
              <Property label="Receiver Phone" value={getValues('receiver')} direction="column" />
              <Property
                label="Receiver Name"
                value={user?.first_name + ' ' + user?.last_name}
                direction="column"
              />
              <Property label="Duration" value={getPeriod()} direction="column" />
              <Property label="Narration" value={getValues('narration')} direction="column" />
            </CardContent>
          </Collapse>
        </Stack>

        <Box
          borderRadius="24px"
          className="bg-white py-2 border border-gray-200"
          w={['full', '50%']}>
          <Text
            fontSize={['lg', isLessThan1280 ? 'lg' : 'xl']}
            fontWeight="bold"
            color={'black'}
            px="6">
            Payment Summary
          </Text>
          <div className="flex flex-col space-y-4 px-8">
            <Stack spacing="4" mt="4" fontSize={isLessThan1280 ? 'sm' : 'md'}>
              <div className="flex justify-between items-end">
                <Text fontWeight="normal" color="gray.400">
                  Amount
                </Text>
                <Text fontWeight="semibold" color="black">
                  UGX {Number(amountInput || 0).toLocaleString()}
                </Text>
              </div>
            </Stack>
            <Divider my="3" />
            <div className="flex justify-between items-end">
              <Text fontWeight="normal" color="black">
                Total
              </Text>
              <Text fontSize="lg" fontWeight="bold" color="black">
                UGX {Number(Number(totalAmount || 0).toFixed(2)).toLocaleString()}
              </Text>
            </div>
            <Text fontSize={isLessThan1280 ? 'xs' : 'md'} className="font-bold italic">
              Mobile Money
            </Text>
            <Button
              mt="4"
              type="submit"
              as={LButton}
              dark
              btnSize={isLessThan1280 ? 'xs' : 'md'}
              disabled={!totalAmount || isSubmitting}
              isLoading={isSubmitting}
              w="50%">
              Pay with Mobile Money
            </Button>

            <div className="col-md-12">
              <Image src={LimboHalfLogo} className="mr-1" alt="Responsive" />
            </div>
          </div>
        </Box>
      </Stack>
    </>
  );
};
