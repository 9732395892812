import React, { useEffect } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';

import { PaymentsTable } from 'app/components/tables/payments';
import { Statistics } from 'app/components/widgets/statistics';
import DataTabs from 'app/components/widgets/data-tabs';
import useArbitrationStore from 'app/state/store/arbitration';
import useTransactionStore from 'app/state/store/transactions';
import useAuthStore from 'app/state/store/auth';

const Transactions = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { allArbitrations, getAllArbitrations } = useArbitrationStore();
  const { allTransactions, getAllPayments } = useTransactionStore();
  const { users, fetchAllUsers } = useAuthStore();

  useEffect(() => {
    const getDataPayments = async () => {
      await getAllPayments();
    };
    const getData = async () => {
      await getAllArbitrations();
    };
    if (allTransactions.length === 0) {
      getDataPayments();
    }
    getData();
  }, []);
  //if there are no users
  useEffect(() => {
    const getData = async () => {
      await fetchAllUsers();
    };
    if (users.length === 0) {
      getData();
    }
  }, []);

  const tabData = [
    {
      label: 'Summary',
      content: <Statistics queryKey="transfer_statistics" />,
    },
    {
      label: 'Arbitrations',
      content: (
        <PaymentsTable
          isMobile={isMobile}
          allArbitrations={allArbitrations}
          transactions={allTransactions}
        />
      ),
    },
  ];

  return (
    <Box w="100%" py={[2, 4]} px={[0, 6]}>
      <DataTabs data={tabData} />
    </Box>
  );
};

export default Transactions;
