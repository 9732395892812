import create from 'zustand';
import { handleError, handleSuccess } from 'app/hooks/backend';
import {
  ApprovePaymentParams,
  CancelPaymentParams,
  CreatePayment,
  IPaymentRepository,
  MakePayment,
} from 'domain/repositories/payment';
import { makePaymentApi } from 'factory';

const paymentsApi = makePaymentApi();

const useTransactionStore = create<IPaymentRepository>((set, get) => ({
  transactions: [],
  summary: {},
  loading: false,
  allTransactions: [],
  getPayments: async (): Promise<void> => {
    try {
      set({ loading: true });
      const data = await paymentsApi.getPayments();
      if (data.data === null) {
        set({ transactions: [] });
        return;
      } else {
        set({ transactions: data.data, loading: false });
        console.log('the response of transactions are:', data.data);
      }
    } catch (error) {
      console.log('Fetch transactions error:', error);
      set({ loading: false });
    }
  },
  //get summary
  getSummary: async (): Promise<void> => {
    try {
      set({ loading: true });
      const data = await paymentsApi.getSummary();

      set({ summary: data.data, loading: false });
      console.log('the all summary data is', data.data);
    } catch (error) {
      console.log('Fetch summary error:', error);
      set({ loading: false });
    }
  },
  //get all payments
  getAllPayments: async (): Promise<void> => {
    try {
      set({ loading: true });
      const data = await paymentsApi.getAllPayments();
      console.log('the all transaction data is', data.data);

      set({ allTransactions: data.data, loading: false });
      console.log('the response of all transactions are:', data.data);
    } catch (error) {
      console.log('Fetch all transactions error:', error);
      set({ loading: false });
    }
  },

  //make payment
  requestPayment: async (values: CreatePayment) => {
    set({ loading: true });
    const { data, error } = await paymentsApi.requestPayment(values);
    set({ loading: false });
    return { data, error };
  },
  //transfer payment
  makePayment: async (values: MakePayment) => {
    set({ loading: true });
    const { data, error } = await paymentsApi.makePayment(values);
    set({ loading: false });
    return { data, error };
  },

  //approve payment
  approvePayment: async (params: ApprovePaymentParams) => {
    set({ loading: true });
    const { data, error } = await paymentsApi.approvePayment(params);
    if (data.status === 200) {
      handleSuccess(data.message);
    } else {
      handleError(data.message);
    }
    set({ loading: false });
    return { data, error };
  },

  //cancel payment
  cancelPayment: async (params: CancelPaymentParams) => {
    set({ loading: true });
    const { data, error } = await paymentsApi.cancelPayment(params);
    if (data.status === 200) {
      handleSuccess(data.message);
    } else {
      handleError(data.message);
    }
    set({ loading: false });
    return { data, error };
  },
}));
export default useTransactionStore;
