import { ProtectedRoute } from 'app/pages/private-route/private-route';
import { AppRoutes } from 'app/pages/routes';
import React from 'react';
import { InvoiceView, PurchaseView } from './form-views';
import NewContractView from './main';

export default function NewContractRoutes() {
  return (
    <>
      <ProtectedRoute path={AppRoutes.NEW_PAYMENT} exact component={NewContractView} />
      <ProtectedRoute path={`${AppRoutes.NEW_PAYMENT}/purchase`} exact component={PurchaseView} />
      <ProtectedRoute path={`${AppRoutes.NEW_PAYMENT}/request`} exact component={InvoiceView} />
    </>
  );
}
