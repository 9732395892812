import create from 'zustand';
// import { getErrorMessage } from 'app/utils';
import { handleError, handleSuccess } from 'app/hooks/backend';
import { makeArbitrationApi } from 'factory';
import { ArbitrationRepository, RaiseArbitration } from 'domain/repositories/arbitration';

const arbitrationsApi = makeArbitrationApi();
const useArbitrationStore = create<ArbitrationRepository>((set, get) => ({
  arbitrations: [],
  loading: false,
  allArbitrations: [],

  getArbitrations: async (): Promise<void> => {
    try {
      set({ loading: true });
      const { data } = await arbitrationsApi.getArbitrations();
      if (data === null) {
        set({ arbitrations: [] });
        return;
      } else {
        set({ arbitrations: data, loading: false });
        console.log('the response of arbitrations are:', data);
      }
    } catch (error) {
      console.log('Fetch arbitrations error:', error);
      set({ loading: false });
    }
  },

  getAllArbitrations: async (): Promise<void> => {
    try {
      set({ loading: true });
      const { data } = await arbitrationsApi.getAllArbitrations();
      if (data === null) {
        set({ allArbitrations: [] });
        return;
      } else {
        set({ allArbitrations: data, loading: false });
        console.log('the response of all arbitrations are:', data);
      }
    } catch (error) {
      console.log('Fetch all arbitrations error:', error);
      set({ loading: false });
    }
  },

  cancelArbitration: async (params?: string) => {
    set({ loading: true });
    const { data, error } = await arbitrationsApi.cancelArbitration(params);
    if (data.status === 200) {
      handleSuccess(data.message);
      // await get().getArbitrations();
    } else {
      handleError(data.message);
    }
    set({ loading: false });
    return { data, error };
  },

  //raise arbitration
  raiseArbitration: async (params: RaiseArbitration) => {
    set({ loading: true });
    try {
      const { data } = await arbitrationsApi.raiseArbitration(params);
      if (data.status === 200) {
        handleSuccess(data.message);
      } else {
        handleError(data.message);
      }
    } catch (error) {
      console.log('the error in raising the arbitration is', error);
    }
    set({ loading: false });
  },
}));

export default useArbitrationStore;
