import React from 'react';
import { getStatusColor } from '../controls/badges';
import useTransactionStore from 'app/state/store/transactions';
import { AppRoutes } from 'app/pages/routes';
import { Feature } from '../controls';
import { useLocation } from 'react-router-dom';
import useArbitrationStore from 'app/state/store/arbitration';

const Card = ({ children, color = 'swamp-green' }: any) => (
  <div className={`p-4 text-${color}-900 bg-${color}-100 rounded`}>{children}</div>
);

export const StatCards = ({ statistics, status }: any) => {
  const location = useLocation();
  const color = getStatusColor(status);
  const { summary, allTransactions } = useTransactionStore();
  const { allArbitrations } = useArbitrationStore();

  const pending = allTransactions.filter(transaction => transaction.status === 'pending');
  const successful = allTransactions.filter(transaction => transaction.status === 'successful');
  const cancelled = allTransactions.filter(transaction => transaction.status === 'cancelled');
  const failed = allTransactions.filter(transaction => transaction.status === 'failed');
  const collected = allTransactions.filter(transaction => transaction.status === 'collected');
  // ARBITRATIONS
  const pendingArbitration = allArbitrations.filter(
    arbitration => arbitration.status === 'pending'
  );
  const successfulArbitration = allArbitrations.filter(
    arbitration => arbitration.status === 'successful'
  );
  const cancelledArbitration = allArbitrations.filter(
    arbitration => arbitration.status === 'cancelled'
  );
  const failedArbitration = allArbitrations.filter(arbitration => arbitration.status === 'failed');

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:w-full my-4">
      <Feature
        permissions={{
          allowed: [{ obj: location, attribute: 'pathname', value: AppRoutes.ARBITRATIONS }],
          rejected: [],
        }}>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Sum</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">
              {(summary?.total_arbitrations || allArbitrations.length || 0).toLocaleString()}
            </p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Pending</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">{(pendingArbitration?.length || 0).toLocaleString()}</p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Successful</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">{(successfulArbitration?.length || 0).toLocaleString()}</p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Cancelled</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">{(cancelledArbitration?.length || 0).toLocaleString()}</p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Failed</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">{(failedArbitration?.length || 0).toLocaleString()}</p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
      </Feature>

      <Feature
        permissions={{
          allowed: [{ obj: location, attribute: 'pathname', value: AppRoutes.PAYMENTS }],
          rejected: [],
        }}>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Sum</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">
              {(summary.total_transactions || allTransactions?.length || 0).toLocaleString()}
            </p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Pending</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">{(pending?.length || 0).toLocaleString()}</p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Successful</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">{(successful?.length || 0).toLocaleString()}</p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Cancelled</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">{(cancelled?.length || 0).toLocaleString()}</p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Failed</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">{(failed?.length || 0).toLocaleString()}</p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
        <Card color={color}>
          <p className={`text-sm uppercase text-${color}-600`}>Collected</p>
          <span className="flex mt-4 space-x-2">
            <p className="text-3xl">{(collected?.length || 0).toLocaleString()}</p>
            <sup className="text-xs">Total</sup>
          </span>
        </Card>
      </Feature>
    </div>
  );
};
