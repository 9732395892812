import React from 'react';
import { range } from 'lodash';
import classnames from 'classnames';

type PaginationProps = { page: number; total: number; limit: number; setPage: Function };

const pageBtnClasses =
  'first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid bg-white border-blue-gray-400 text-blue-gray-500';

export default function Pagination({ page, total, limit, setPage }: PaginationProps) {
  const totalPages = Math.ceil(total / limit);
  const startPage = Math.max(1, page - 5);
  const endPage = Math.min(startPage + 9, totalPages);
  const pages = range(startPage, endPage + 1);

  return (
    <div className="py-2">
      <nav className="block">
        <ul className="flex pl-0 rounded list-none flex-wrap">
          <li>
            <button
              disabled={page <= 1}
              onClick={() => setPage(1)}
              className={classnames(pageBtnClasses, { 'opacity-25': page <= 1 })}>
              <i className="fas fa-chevron-left -ml-px"></i>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          <li>
            <button
              disabled={page <= 1}
              onClick={() => setPage(page - 1)}
              className={classnames(pageBtnClasses, { 'opacity-25': page <= 1 })}>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          {pages.map(pageNo => (
            <li key={pageNo}>
              <button
                key={pageNo}
                className={classnames(pageBtnClasses, { 'bg-blue-gray-100': pageNo === page })}
                onClick={() => setPage(pageNo)}>
                {pageNo}
              </button>
            </li>
          ))}
          <li>
            <button
              disabled={page >= totalPages}
              onClick={() => setPage(page + 1)}
              className={classnames(pageBtnClasses, { 'opacity-25': page >= totalPages })}>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
          <li>
            <button
              disabled={page >= totalPages}
              onClick={() => setPage(totalPages)}
              className={classnames(pageBtnClasses, { 'opacity-25': page >= totalPages })}>
              <i className="fas fa-chevron-right -ml-px"></i>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}
