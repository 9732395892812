import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../routes';
import { Helmet } from 'react-helmet';
import './styles/contact-banner.css';

export const ContactBanner = () => (
  <section className="bg-imgSec mt-5">
    <Helmet>
      <meta charSet="utf-8" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
      />
    </Helmet>
    <Box
      w={['100%', '78%']}
      className="col-md-6 mx-auto pt-5 wow animate__fadeInLeft animate__animated text-white"
      data-wow-duration="1s">
      <Text fontSize={['3xl', '6xl']} fontWeight="bold">
        Questions? Contact Limbo Support
      </Text>
      <Text mt="1" mb="5" fontSize={['md', '3xl']}>
        If you have any query or questions, write to us or simply call.
      </Text>
      <Link to={AppRoutes.CONTACT}>
        <button className="font-black rounded-full text-white border border-white hover:bg-white hover:text-black px-16 py-2 focus:outline-none bg-transparent">
          Contact Us
        </button>
      </Link>
    </Box>
  </section>
);
