import { HttpClient, HttpRequest, HttpResponse } from 'data/protocols/http';

import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { NetworkError } from 'data/api/errors/network-error';

export class AxiosHttpClient implements HttpClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create();
    this.client.interceptors.response.use(this.handleResponse, this.handleError);
  }

  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse;

    try {
      axiosResponse = await this.client.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: data.headers,
      });
    } catch (error: any) {
      if (error.message === 'Network Error') throw new NetworkError();
      axiosResponse = error.response;
    }
    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    };
  }

  // eslint-disable-next-line
  async handleResponse(response: any): Promise<any> {
    switch (response.status) {
      case 200:
        if (response.data.message) {
          console.log(response.data.message);
        }
        break;
      case 201:
        break;
      case 204:
        if (response.config.method === 'delete') {
          console.log('Record deleted successfully');
        } else if (response.config.method === 'put') {
          console.log('Record updated successfully');
        }
        break;
      default:
        break;
    }
    return response;
  }

  // eslint-disable-next-line
  async handleError(error: any): Promise<never> {
    console.log('Error', error);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.error('Unauthorized');
          break;

        case 404:
          console.error(`${error.response.config.url} was not found on the server`);
          break;

        case 405:
          console.error('Method not allowed');
          break;

        default:
          console.error(error.response.data || error.response.data.message);
          break;
      }
    }
    return Promise.reject(error);
  }
}
