import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AppRoutes } from 'app/pages/routes';
import { publicRoutes } from 'app/services/constants';
import { currentUserState } from 'app/state/atoms';
import { useCookies } from '../cookies';

export const useSession = () => {
  const history = useHistory();
  let location = useLocation();
  const { getCookie } = useCookies();
  const { getCurrentUser } = useRecoilValue(currentUserState);

  const currentProfile = getCurrentUser();
  const token = getCookie('session');

  const isAuthenticated = !!currentProfile && !!token;

  useEffect(() => {
    const hasNoToken = !token;

    if (hasNoToken && !publicRoutes.includes(location.pathname)) {
      history.push(AppRoutes.LOGIN);
    }

    // eslint-disable-next-line
  }, [currentProfile, location]);

  return {
    user: currentProfile,
    isAuthenticated: isAuthenticated,
  };
};
