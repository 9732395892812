import {
  As,
  Box,
  Button,
  ButtonProps,
  Flex,
  FormLabel,
  HStack,
  Input,
  OmitCommonProps,
  PinInput,
  PinInputField,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  InputRightElement,
  InputGroup,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { handleError, handleSuccess, useAuth, useProfiles } from 'app/hooks/backend';
import { AppRoutes } from 'app/pages/routes';
import Config from 'app/services/config';
import { currentUserState } from 'app/state/atoms';
import { useCustomerStore } from 'app/state/store/customers';
import { Regex } from 'app/utils/regex';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { LButton } from '../widgets/buttons';
import { Label, PasswordInput } from './fields';
import { PhonenumberInput } from './fields/phone';
import { UserProfileForm } from './profile';
import { useFormErrors } from './utils';
import useAuthStore from 'app/state/store/auth';
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';

export const FormButton = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<
      React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
      keyof ButtonProps
    > &
    ButtonProps &
    OmitCommonProps<any, keyof ButtonProps> & { as?: As<any> | undefined }
) => (
  <Button
    {...props}
    type="submit"
    className="w-full"
    bgColor={`${Config.THEME.primary}.800`}
    color="white"
    rounded="full"
    _hover={{ bg: `${Config.THEME.primary}.700` }}>
    {props.children}
  </Button>
);

export const LoginForm = () => {
  const { onLogin, loading } = useAuth();
  const { handleSubmit, register, errors, control } = useForm<FormData>();

  useFormErrors(errors);

  const onSubmit = handleSubmit(async data => {
    await onLogin(data);
  });

  return (
    <form onSubmit={onSubmit}>
      <SimpleGrid columns={[1, 2]} gap="8" mb="8" w="full">
        <Stack spacing={2}>
          <Label>Username</Label>
          <PhonenumberInput name="username" control={control} />
        </Stack>
        <Stack spacing={2}>
          <Label>Password</Label>
          <PasswordInput variant="outline" focusBorderColor="black" ref={register} />
        </Stack>
      </SimpleGrid>
      <Button as={LButton} dark isLoading={loading}>
        Log In
      </Button>
    </form>
  );
};

export const VerifyForm = () => {
  const { onVerifyCode, sendVerifyCode, loading, profile } = useProfiles();
  const { handleSubmit, control, errors } = useForm<any>({
    defaultValues: {
      code: null,
    },
  });

  useFormErrors(errors);

  const sendCode = async () => {
    await sendVerifyCode({ channel: 'phone' });
  };

  React.useEffect(() => {
    if (!profile.verified) {
      sendCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit(async data => {
    await onVerifyCode(data.code);
  });

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={4} mb="6">
        <Label>Enter Code</Label>
        <Controller
          name="code"
          control={control}
          rules={{
            required: true,
            pattern: { value: Regex.VALID_VERIFICATION_CODE, message: 'Invalid code' },
          }}
          render={({ onChange, value }) => (
            <>
              <HStack>
                <PinInput onChange={onChange} value={value} autoFocus type="alphanumeric">
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              <Flex direction="row-reverse">
                <button
                  className="focus:outline-none cursor-pointer"
                  onClick={e => {
                    e.preventDefault();
                    sendCode();
                  }}>
                  <Text
                    fontSize={['xs', 'sm']}
                    color="gray.500"
                    _hover={{ color: 'eastern-blue.500', textDecoration: 'underline' }}>
                    Resend Code
                  </Text>
                </button>
              </Flex>
            </>
          )}
        />
      </VStack>
      <Button as={LButton} dark isLoading={loading}>
        Verify
      </Button>
    </form>
  );
};

export const VerifyFormReset = () => {
  const { handleSubmit, control, errors } = useForm<any>({
    defaultValues: {
      code: null,
    },
  });
  const history = useHistory();
  const { verifyPasswordReset, usernameForReset, loading, resetPassword } = useAuthStore();
  useFormErrors(errors);

  const sendCode = async () => {
    if (usernameForReset === '') {
      handleError('Go back to reset password page and insert your phone number');
      return;
    }
    const response = await resetPassword({ username: usernameForReset, channel: 'phone' });
    console.log('the response', response);
    if (response.status === 200) {
      handleSuccess(response.message);
    } else {
      handleError(response.mesage);
    }
  };

  const onSubmit = handleSubmit(async data => {
    const response = await verifyPasswordReset({ otp: data.code, username: usernameForReset });
    console.log('the response', response);
    if (response.status === 200) {
      handleSuccess('Verification is successful, reset your password');
      history.push(AppRoutes.RESET_PASSWORD_FORM);
    } else {
      handleError(response.mesage);
      return;
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={4} mb="6">
        <Label>Enter Code</Label>
        <Controller
          name="code"
          control={control}
          rules={{
            required: true,
            pattern: { value: Regex.VALID_VERIFICATION_CODE, message: 'Invalid code' },
          }}
          render={({ onChange, value }) => (
            <>
              <HStack>
                <PinInput onChange={onChange} value={value} autoFocus type="alphanumeric">
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              <Flex direction="row-reverse">
                <button
                  className="focus:outline-none cursor-pointer"
                  onClick={e => {
                    e.preventDefault();
                    sendCode();
                  }}>
                  <Text
                    fontSize={['xs', 'sm']}
                    color="gray.500"
                    _hover={{ color: 'eastern-blue.500', textDecoration: 'underline' }}>
                    Resend Code
                  </Text>
                </button>
              </Flex>
            </>
          )}
        />
      </VStack>
      <Button as={LButton} dark isLoading={loading}>
        Verify
      </Button>
    </form>
  );
};

export const SignUpForm = () => {
  const { onSignUp, loading } = useAuth();
  const methods = useForm<any>();
  const { handleSubmit, register, errors } = methods;

  const onSubmit = handleSubmit(async data => {
    await onSignUp({ ...data, tags: ['customer', 'web'] });
  });

  useFormErrors(errors);

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing="8" mb="8">
        <UserProfileForm formMethods={methods} />
        <div>
          <FormLabel>Password</FormLabel>
          <PasswordInput variant="outline" focusBorderColor="black" ref={register} />
        </div>
      </Stack>
      <Button dark type="submit" as={LButton} isLoading={loading}>
        Create Account
      </Button>
    </form>
  );
};

type Reset = {
  username: string;
};

export const ResetStartForm = () => {
  const { handleSubmit, errors, control } = useForm<Reset>();
  const { loading, resetPassword } = useAuthStore();
  useFormErrors(errors);
  const history = useHistory();
  const onSubmit = handleSubmit(async data => {
    const response = await resetPassword({ username: data.username, channel: 'phone' });
    if (response.status === 200) {
      handleSuccess(response.message);
      history.push(AppRoutes.VERIFYRESTPASWORD);
    } else {
      handleError(response.message);
      return;
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing="8">
        <div>
          <Label>Enter your phone Number</Label>
          <PhonenumberInput name="username" control={control} />
        </div>
        <Button as={LButton} dark isLoading={loading}>
          Reset Password
        </Button>
      </Stack>
    </form>
  );
};

interface ChangeFormData {
  password: string;
  passwordConfirm: string;
  currentPassword: string;
}

export const ChangePasswordForm = () => {
  const { changePassword, loading } = useAuthStore();
  const { handleSubmit, register, watch, errors } = useForm<ChangeFormData>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [showCurrPassword, setShowCurrPassword] = useState(false);

  const history = useHistory();
  useFormErrors(errors);
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = handleSubmit(async data => {
    const { password, currentPassword } = data;
    const response = await changePassword({
      new_password: password,
      old_password: currentPassword,
    });
    if (response.status === 200) {
      handleSuccess(response.message);
      history.push(AppRoutes.HOME);
    } else {
      handleError(response.mesage);
      return;
    }
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing="8">
          {/* CURRENT PASSWORD */}
          <div>
            <Label>Current Password</Label>
            <InputGroup>
              <Input
                variant="outline"
                name="currentPassword"
                type={showCurrPassword ? 'text' : 'password'}
                required
                focusBorderColor="black"
                placeholder="Enter old password"
                ref={register({
                  required: 'You must put your old password',
                  minLength: {
                    value: 6,
                    message: 'Password must have at least 6 characters',
                  },
                })}
              />
              <InputRightElement onClick={() => setShowCurrPassword(!showCurrPassword)}>
                {showCurrPassword ? <ViewOffIcon /> : <ViewIcon />}
              </InputRightElement>{' '}
            </InputGroup>
          </div>
          {/* NEW PASSWORD */}
          <div>
            <Label>New Password</Label>
            <InputGroup>
              <Input
                variant="outline"
                name="password"
                type={showPassword ? 'text' : 'password'}
                required
                focusBorderColor="black"
                placeholder="Enter new password"
                ref={register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 6,
                    message: 'Password must have at least 6 characters',
                  },
                })}
              />
              <InputRightElement onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
              </InputRightElement>
            </InputGroup>
          </div>
          {/* CONFIRM PASSWORD */}
          <div>
            <Label>Confirm Password</Label>
            <InputGroup>
              <Input
                variant="outline"
                name="passwordConfirm"
                type={showConfPassword ? 'text' : 'password'}
                focusBorderColor="black"
                placeholder="Confirm new password"
                required
                ref={register({
                  validate: value => value === password.current || 'The passwords do not match',
                })}
              />

              <InputRightElement onClick={() => setShowConfPassword(!showConfPassword)}>
                {showConfPassword ? <ViewOffIcon /> : <ViewIcon />}
              </InputRightElement>
            </InputGroup>
          </div>
          <Button as={LButton} dark isLoading={loading}>
            Change Password
          </Button>
        </Stack>
      </form>
    </>
  );
};

//reset password form
export const ResetPasswordFormData = () => {
  const { loading, passwordReset, usernameForReset, resetToken } = useAuthStore();
  const { handleSubmit, register, watch, errors } = useForm<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const history = useHistory();

  useFormErrors(errors);

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = handleSubmit(async data => {
    const response = await passwordReset({
      channel: 'phone',
      new_password: data.password,
      reset_token: resetToken,
      username: usernameForReset,
    });
    if (response.status === 200) {
      handleSuccess(response.message);
      history.push(AppRoutes.LOGIN);
    } else {
      handleError(response.message);
    }
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing="8">
          {/* NEW PASSWORD */}
          <div>
            <Label>New Password</Label>
            <InputGroup>
              <Input
                variant="outline"
                name="password"
                type={showPassword ? 'text' : 'password'}
                required
                focusBorderColor="black"
                placeholder="Enter new password"
                ref={register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 6,
                    message: 'Password must have at least 6 characters',
                  },
                })}
              />
              <InputRightElement onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
              </InputRightElement>
            </InputGroup>
          </div>
          {/* CONFIRM PASSWORD */}
          <div>
            <Label>Confirm Password</Label>
            <InputGroup>
              <Input
                variant="outline"
                name="passwordConfirm"
                type={showConfPassword ? 'text' : 'password'}
                focusBorderColor="black"
                placeholder="Confirm new password"
                required
                ref={register({
                  validate: value => value === password.current || 'The passwords do not match',
                })}
              />

              <InputRightElement onClick={() => setShowConfPassword(!showConfPassword)}>
                {showConfPassword ? <ViewOffIcon /> : <ViewIcon />}
              </InputRightElement>
            </InputGroup>
          </div>
          <Button as={LButton} dark isLoading={loading}>
            Change Password
          </Button>
        </Stack>
      </form>
    </>
  );
};

export const ChooseEntityForm = () => {
  const history = useHistory();
  const { profiles } = useCustomerStore();
  const [chosenProfileId, setProfileId] = useState(profiles[0]?.uuid);
  const { setCurrentUser } = useRecoilValue(currentUserState);

  const chooseProfile = (value: any) => {
    setProfileId(value);
  };

  const handleSubmit = () => {
    const profile = profiles.find((p: any) => p.uuid === chosenProfileId);
    setCurrentUser(profile);
    history.push(AppRoutes.DASHBOARD);
  };

  return (
    <div className="flex flex-col space-y-6">
      <RadioGroup defaultValue={profiles[0]?.uuid} onChange={chooseProfile} w="full">
        <VStack spacing="1" w="full">
          {profiles?.map((item: any) => (
            <Box
              key={item?.uuid}
              borderColor={{ color: mode('gray.200', 'gray.100') }}
              className={classnames(
                'flex items-center p-2 border-2 rounded w-full',
                item?.uuid === chosenProfileId && 'border-blue-400'
              )}>
              <Radio value={item.uuid} />
              <div className="flex ml-6 items-center justify-between w-full">
                <div>
                  <Text fontSize="md" color={`${Config.THEME.primary}.800`}>
                    {item?.entity?.name}
                  </Text>
                  {item?.account.invoices ? (
                    <Text fontSize="xs" color={mode('gray.500', 'gray.400')}>
                      <span>House Purchases: {item.account.invoices.toLocaleString()}</span>
                    </Text>
                  ) : item?.account.contracts ? (
                    <Text fontSize="xs" color={mode('gray.500', 'gray.400')}>
                      <span>Contract: {item.account.contracts.toLocaleString()}</span>
                    </Text>
                  ) : null}
                </div>
                <div>
                  <Text fontSize="sm" color={mode('blue.500', 'blue.200')} fontWeight="semibold">
                    UGX {Number(item.account.balance.toFixed()).toLocaleString()}
                  </Text>
                </div>
              </div>
            </Box>
          ))}
        </VStack>
      </RadioGroup>
      <Button as={LButton} dark onClick={handleSubmit}>
        Continue
      </Button>
    </div>
  );
};
