// Auth usecases
import {
  ChangePassword,
  GetUsersParams,
  IUserRepository,
  ResetPassword,
  SendCodeParams,
  UserProfile,
  VerifyCodeParams,
} from 'domain/repositories/user';
import { IDomainResponse } from '.';

export class UsersInteractor {
  constructor(private readonly userRepo: IUserRepository) {}

  //getting a single user profile
  async getUserProfile(username: string): Promise<IDomainResponse> {
    try {
      const data = await this.userRepo.getUserProfile(username);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //reset password
  async resetPassword(params: ResetPassword): Promise<IDomainResponse> {
    try {
      const data = await this.userRepo.resetPassword(params);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async getUsers(params: GetUsersParams): Promise<IDomainResponse> {
    return await this.userRepo.getUsers(params);
  }

  async getProfile(): Promise<IDomainResponse> {
    try {
      const data = await this.userRepo.getProfile();
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async createProfile(params: UserProfile) {
    try {
      const data = await this.userRepo.createProfile(params);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async updateProfile(values: UserProfile) {
    try {
      const data = await this.userRepo.updateProfile(values);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //change password
  async changePassword(params: ChangePassword) {
    try {
      const data = await this.userRepo.changePassword(params);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //suspend user
  async suspendUser(username?: string) {
    try {
      const data = await this.userRepo.suspendUser(username);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async sendVerifyCode(channel: SendCodeParams) {
    console.log('the channel is', channel.channel);
    try {
      const data = await this.userRepo.sendVerifyCode({ channel: channel.channel });
      console.log('the data of code is', data);

      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async verifyCode(code: VerifyCodeParams) {
    console.log('the code is', code);
    try {
      const data = await this.userRepo.verifyCode(code);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  //get All Users
  async getAllUsers(): Promise<IDomainResponse> {
    try {
      const data = await this.userRepo.getAllUsers();
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
}
