import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import { Feature } from 'app/components/controls';
import { TarriffForm } from 'app/components/forms/tarriff';
import { TarriffsTable } from 'app/components/tables/tarriffs';
import { LButton } from 'app/components/widgets/buttons';
import { useSession } from 'app/contexts/session';

const TarriffEditModal = ({ isOpen, onClose, record }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="3xl" motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Tarriff Details</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <TarriffForm tarriff={record} onComplete={onClose} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

const TarriffsView = () => {
  const { user } = useSession();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box w="100%" py={[2, 4]} px={[0, 6]}>
      <Tabs variant="unstyled" isLazy>
        <TabList>
          <Tab
            _focus={{ boxShadow: 'none', outline: 'none' }}
            borderBottom="2px"
            borderBottomColor="transparent"
            _selected={{ borderBottomColor: 'limbo.secondary', color: 'limbo.secondary' }}>
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-3 h-3 md:w-5 md:h-5"
              viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
            <Text fontSize={['0.85rem', 'md']} className="font-semibold ml-2">
              Tarriffs
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <Feature
                permissions={{
                  allowed: [{ obj: user, attribute: 'is_superuser', value: true }],
                  rejected: [],
                }}>
                <Button as={LButton} dark type="button" btnSize="sm" onClick={onOpen}>
                  Create Tarriff
                </Button>
              </Feature>
              <Box mt={4} />
              <TarriffsTable filters={{ per_page: 10 }} />
              <TarriffEditModal isOpen={isOpen} onClose={onClose} record={null} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TarriffsView;
