import Page from 'app/components/page';
import PublicLayout from './layout';
import React, { useState } from 'react';
import { Text, Tabs, TabList, TabPanels, Tab, TabPanel, Box, Stack } from '@chakra-ui/react';
import { Feature } from 'app/components/controls';
import { useSession } from 'app/hooks/backend/session';
import { ArbitrationsTable } from 'app/components/tables/arbitrations';
import { useProfiles } from 'app/hooks/backend';

export default function Arbitration() {
  const { isAuthenticated } = useSession();
  const { profile } = useProfiles();

  const tabMenus = [
    { label: 'All', status: '' }, // Empty status indicates all arbitrations
    { label: 'In Progress', status: 'pending' },
    { label: 'Dispute Ended', status: 'cancelled' },
    { label: 'You Won', status: 'won' },
    { label: 'You Lost', status: 'lost' },
  ];
  //// State to track the selected tab
  const [selectedTab, setSelectedTab] = useState('');

  const handleTableClick = (status: string) => {
    setSelectedTab(status);
  };

  const [filters, setFilters] = useState<any>({});

  const handleOnClick = () => {
    if (filters.hasOwnProperty('created_by')) {
      delete filters['created_by'];
      setFilters({ ...filters });
    } else {
      setFilters({ ...filters, created_by: profile.username });
    }
  };

  return (
    <PublicLayout title="Arbitration">
      <Page>
        <Box minHeight="10rem">
          <Feature on={isAuthenticated}>
            <Text fontSize="2xl" fontWeight="extrabold" color="black">
              Past Arbitration
            </Text>
            <Tabs variant="unstyled" minHeight="md">
              <TabList>
                <Stack w="full" spacing={[0, 2]} direction={['column', null, 'row']}>
                  {tabMenus.map(item => (
                    <Tab
                      key={item.label}
                      fontWeight="semibold"
                      fontSize={['md', 'lg']}
                      opacity={0.5}
                      textAlign="left"
                      border={['none', null, '1px solid transparent']}
                      borderRadius="3xl"
                      _selected={{
                        borderBottomColor: [null, null, 'black'],
                        opacity: 1,
                        border: ['1px solid black', null, null],
                      }}
                      _focus={{ outline: 'none' }}
                      onClick={() => handleTableClick(item.status)}>
                      {item.label}
                    </Tab>
                  ))}
                </Stack>
              </TabList>
              <TabPanels>
                {tabMenus.map(item => (
                  <TabPanel key={`panel-${item.label}`}>
                    <Box
                      bg="white"
                      p={2}
                      borderWidth="1px"
                      borderRadius="lg"
                      borderColor="gray.300">
                      {/* arbitration table */}
                      <ArbitrationsTable status={item.status || selectedTab} filters={filters} />
                    </Box>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Feature>
        </Box>
      </Page>
    </PublicLayout>
  );
}
