import React from 'react';
import { Switch } from 'react-router';
import { ProtectedRoute } from '../private-route/private-route';

import { AppRoutes } from '../routes';
import TransactionsView from './transactions';
import UserProfile from './profile';
import NewContractRoutes from './new-contract';
import ArbitrationsView from './arbitrations';

export default function UserAccountRoutes() {
  return (
    <Switch>
      <ProtectedRoute path={AppRoutes.USER_PROFILE} exact component={UserProfile} />
      <ProtectedRoute path={AppRoutes.USER_PAYMENTS} exact component={TransactionsView} />
      <ProtectedRoute path={AppRoutes.USER_ARBITRATIONS} exact component={ArbitrationsView} />
      <NewContractRoutes />
    </Switch>
  );
}
