import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  Center,
  Skeleton,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ContractStatus, getStatusColor } from '../controls/badges';
import Pagination from '../controls/pagination';

import { PaymentDetails } from '../widgets/payment-detail';
import { DateInfo, LimboUser } from '../widgets/misc';
import useArbitrationStore from 'app/state/store/arbitration';
import { Feature } from '../controls';
import { Arbitration, PaymentCreateParams } from 'domain/repositories/payment';
import useTransactionStore from 'app/state/store/transactions';
import useAuthStore from 'app/state/store/auth';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  record: Arbitration | null;
  transaction: PaymentCreateParams | null;
};

const ViewRecordModal = ({ isOpen, onClose, record, transaction }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} size="3xl" motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>View Details</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <PaymentDetails payment={record} transaction={transaction} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

export const ArbitrationsTable = ({ status, filters }: { status: string; filters: any }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [page, setPage] = useState(1);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const { arbitrations, loading, getArbitrations } = useArbitrationStore();
  const { transactions, getPayments } = useTransactionStore();
  const { fetchAllUsers, users } = useAuthStore();

  const openViewRecordModel = (item: any, transaction: any) => {
    setSelectedRecord(item);
    setTransaction(transaction);
    onOpen();
  };

  useEffect(() => {
    const getDataPayment = async () => {
      await getPayments();
    };
    const getData = async () => {
      await getArbitrations();
    };
    if (transactions.length === 0) {
      getDataPayment();
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const getUsers = async () => {
      await fetchAllUsers();
    };
    if (users.length === 0) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //filtering arbitrations based on status
  let filteredArbitrations: Arbitration[] = arbitrations;
  if (status) {
    filteredArbitrations = arbitrations.filter((item: Arbitration) => item.status === status);
  }

  if (filters.hasOwnProperty('created_by')) {
    filteredArbitrations = arbitrations.filter((item: Arbitration) => {
      return item.created_by === filters.created_by;
    });
  }

  // Calculate pagination
  const limit = 10; // Number of transactions per page
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedArbitrations = filteredArbitrations.slice(startIndex, endIndex);

  const total: number = filteredArbitrations?.length || 0;

  if (total === 0)
    return <Center>{`No ${status} arbitrations for now. We hope it will stay the same 🙂`}</Center>;
  return (
    <>
      <Feature on={isMobile}>
        <Skeleton isLoaded={!loading}>
          <Box className="lg:hidden flex flex-col space-y-2 text-sm">
            {paginatedArbitrations?.map((item: Arbitration) => {
              const transaction = transactions.find(
                transaction => transaction.uuid === item.transaction_id
              );
              const amount = transaction?.amount;
              const sender = transaction?.sender;

              return (
                <Box
                  key={`mobile-${item.id}`}
                  p={2}
                  borderLeftColor={`${getStatusColor(item.status?.toLowerCase())}.500`}
                  borderLeftWidth="4px"
                  rounded="md"
                  bg={`${getStatusColor(item.status?.toLowerCase())}.100`}
                  onClick={() => openViewRecordModel(item, transaction)}>
                  <Box className="flex justify-between border-b-0">
                    <div className="flex flex-col max-w-1/2">
                      <span className="font-black">
                        {dayjs(item.created_at).format('DD MMM, YYYY')}
                      </span>
                      <i className="font-semibold text-gray-600">{transaction?.pay_type}</i>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-black text-right">{amount}</span>
                      <i className="font-black text-gray-600 text-right text-sm flex flex-end">
                        {sender}
                      </i>
                    </div>
                  </Box>
                  <Text mt="1" fontSize="0.75rem">
                    {item?.message}
                  </Text>
                </Box>
              );
            })}
            <span className="mb-4" />
          </Box>
        </Skeleton>
      </Feature>
      {/* displaying the arbitrations on web >mobile screen */}
      <Feature on={!isMobile}>
        <Box overflowX="auto">
          <Skeleton isLoaded={!loading}>
            <Table size="sm" variant="striped" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Amount</Th>
                  <Th>Payer</Th>
                  <Th>Receiver</Th>
                  <Th>Status</Th>
                  <Th>Date</Th>
                  <Th>Reason</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedArbitrations?.map((item: Arbitration) => {
                  const transaction = transactions.find(
                    transaction => transaction.uuid === item.transaction_id
                  );
                  const amount = transaction?.amount;
                  const sender = transaction?.sender;
                  const receiver = transaction?.receiver;
                  return (
                    <Tr key={item.id}>
                      <Td>{item.id}</Td>
                      <Td>{amount}</Td>
                      <Td>
                        <LimboUser username={sender} />
                      </Td>
                      <Td>
                        <LimboUser username={receiver} />
                      </Td>
                      <Td>
                        <ContractStatus status={item.status} />
                      </Td>
                      <Td>
                        <Text width="6rem">
                          <DateInfo date={item.created_at} />
                        </Text>
                      </Td>
                      <Td>
                        <Text width="15rem" noOfLines={3}>
                          {item.message}
                        </Text>
                      </Td>
                      <Td>
                        <Button
                          onClick={() => openViewRecordModel(item, transaction)}
                          size="xs"
                          colorScheme="blue-gray">
                          View
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Skeleton>
        </Box>
      </Feature>

      <Pagination page={page} total={total} limit={limit} setPage={setPage} />
      <ViewRecordModal
        isOpen={isOpen}
        onClose={onClose}
        record={selectedRecord}
        transaction={transaction}
      />
    </>
  );
};
