export const AppRoutes = {
  HOME: '/',
  ABOUT: '/about',
  CONTACT: '/contact',
  ARBITRATION: '/arbitration',
  LOGIN: '/login',
  DASHBOARD: '/home',
  PAYMENTS: '/payments',
  ARBITRATIONS: '/arbitrations',
  ACCOUNTS: '/accounts',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  LOGOUT: '/logout',
  NOT_FOUND: '/404',
  SIGNUP: '/signup',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  CHOOSE_ENTITY: '/choose-entity',
  USER_PAYMENTS: '/user-payments',
  USER_ARBITRATIONS: '/user-arbitrations',
  NEW_PAYMENT: '/new-payment',
  USER_PROFILE: '/user-profile',
  USERS: '/users',
  TARRIFFS: '/tarriffs',
  TERMS: '/terms',
  VERIFY: '/verify-code',
  VERIFYRESTPASWORD: '/verify-reset-password',
  RESET_PASSWORD_FORM: '/reset-password-form',
};
