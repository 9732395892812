import { InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useProfiles } from 'app/hooks/backend';
import { AppRoutes } from 'app/pages/routes';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import ReactJson from 'react-json-view';
import { useLocation } from 'react-router-dom';
import { Feature } from '../controls';
import { ContractStatus, LBadge } from '../controls/badges';
import { LButton } from './buttons';
import { ApproveOrCancelButtons } from './buttons/approve-or-cancel';
import { Copier, DateInfo, Tags } from './misc';
import { PaymentCreateParams } from 'domain/repositories/payment';
import { RaiseArbitration } from './raiseArbitration';
import useTransactionStore from 'app/state/store/transactions';
import { UserProfile } from 'domain/repositories';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

type Propss = {
  contract: PaymentCreateParams;
  user: UserProfile;
  location: any;
};

const ContractStatusInfo = ({ location, user, contract }: Propss) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [reason, setReason] = useState<any>();

  const { cancelPayment, loading } = useTransactionStore();

  const handleCancel = () => {
    cancelPayment({ transaction_id: contract.uuid, reason });
    onClose();
  };

  return (
    <>
      <Feature
        permissions={{
          allowed: [
            { obj: location, attribute: 'pathname', value: AppRoutes.USER_PAYMENTS },
            { obj: contract, attribute: 'receiver', value: user.username },
            { obj: contract, attribute: 'status', value: 'collected' },
          ],
          rejected: [{ obj: contract, attribute: 'status', value: 'successful' }],
        }}>
        <Alert status="info" rounded="sm" fontSize="sm" p="1.5" mt="1.5">
          <AlertIcon boxSize="16px" mr="1.5" />
          Contact the payer to approve this payment
        </Alert>
      </Feature>
      <Feature
        permissions={{
          allowed: [
            { obj: location, attribute: 'pathname', value: AppRoutes.USER_PAYMENTS },
            { obj: contract, attribute: 'status', value: 'paused' },
          ],
          rejected: [{ obj: contract, attribute: 'status', value: 'successful' }],
        }}>
        <Alert
          status="info"
          rounded="sm"
          fontSize="sm"
          colorScheme="gimblet"
          p="1.5"
          mt="1.5"
          className="flex items-center w-full">
          <AlertIcon boxSize="16px" mr="1.5" />
          {contract.meta?.cancel_log && contract.meta?.cancel_log[0].actioner === user.username ? (
            'Contact the other party to cancel this payment'
          ) : (
            <div className="flex justify-between items-center w-full">
              <span>The other party has requested you to cancel this payment</span>
              <HStack spacing="1">
                <Tooltip label={<div className="text-sm">Cancel the payment</div>}>
                  <IconButton
                    colorScheme="sushi"
                    rounded="full"
                    variant="solid"
                    size="xs"
                    aria-label="Allow the other party to cancel this payment"
                    onClick={onOpen}
                    icon={loading ? <Spinner size="xs" /> : <FaCheck />}
                  />
                </Tooltip>
                <Tooltip
                  label={
                    <div className="text-sm">Reject the cancellation and go to arbitration</div>
                  }>
                  <IconButton
                    colorScheme="contessa"
                    size="xs"
                    variant="solid"
                    rounded="full"
                    aria-label="Allow the other party to cancel this payment"
                    onClick={onOpen}
                    icon={loading ? <Spinner size="xs" /> : <FaTimes />}
                  />
                </Tooltip>
              </HStack>
            </div>
          )}
        </Alert>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Provide a reason</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Textarea
                variant="outline"
                type="text"
                name="reason"
                focusBorderColor="black"
                placeholder="Reason"
                onInput={(e: any) => setReason(e.target.value)}
              />
            </ModalBody>

            <ModalFooter>
              <HStack spacing="1">
                <Button btnSize="sm" as={LButton} onClick={() => handleCancel()}>
                  Cancel and Refund Payer
                </Button>
                <Button btnSize="sm" as={LButton} dark onClick={() => handleCancel()}>
                  Dispute Cancellation
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Feature>
    </>
  );
};

type Props = {
  contract: PaymentCreateParams;
};
export const ContractDetail = ({ contract }: Props) => {
  const { profile: user } = useProfiles();
  const contractAmount = Number(contract?.amount);
  const [showMetadata, setShowMetadata] = useState(false);
  const changeView = () => setShowMetadata(!showMetadata);
  const location = useLocation();

  // Calculate the difference between the due date and current date
  const daysDifference = dayjs(contract?.due_date).diff(dayjs(), 'day');
  // Check if the contract is expired
  const isExpired = daysDifference < 0;

  return (
    <>
      <div className="flex items-center justify-between w-full">
        <HStack spacing={2}>
          <ContractStatus status={contract?.status} />
          <Tooltip
            label={
              <div className="text-sm">
                {contract.status === 'failed'
                  ? 'No payment collected'
                  : 'Contact the customer to approve this payment'}
              </div>
            }>
            <InfoIcon />
          </Tooltip>
        </HStack>
        <Feature
          permissions={{
            allowed: [
              { obj: location, attribute: 'pathname', value: AppRoutes.PAYMENTS },
              { obj: user, attribute: 'is_staff', value: true },
            ],
            rejected: [],
          }}>
          <Button
            as={LButton}
            type="button"
            btnSize="sm"
            colorScheme="blue-gray"
            onClick={changeView}>
            {showMetadata ? 'Details' : 'Metadata'}
          </Button>
        </Feature>
        {/* RAISE ARBITRATION button*/}
        <Stack>
          <Feature
            permissions={{
              rejected: [
                {
                  obj: contract,
                  attribute: 'status',
                  value: ['successful'],
                },
              ],
            }}>
            <RaiseArbitration contract={contract} />
          </Feature>
          <Feature
            permissions={{
              rejected: [
                {
                  obj: contract,
                  attribute: 'status',
                  value: ['cancelled', 'failed', 'successful'],
                },
              ],
            }}>
            <ApproveOrCancelButtons contract={contract} />
          </Feature>
        </Stack>
      </div>
      <ContractStatusInfo location={location} user={user} contract={contract} />
      {showMetadata ? (
        <Box my="6">
          <div className="flex items-center w-full">
            <ReactJson src={contract?.meta} />
          </div>
        </Box>
      ) : (
        <Box mb="2">
          <div className="h-24 flex items-center divide-x divide-solid w-full">
            <span className="w-1/2 flex flex-col items-center">
              <p className="text-xs md:text-md">Reference</p>
              <p className="font-black text-lg md:font-semibold lg:text-2xl">
                <Copier>{contract?.narration}</Copier>
              </p>
            </span>
            <span className="w-1/2 flex flex-col items-center">
              <p className="text-xs md:text-md">Created On</p>
              <p className="font-black text-lg md:font-semibold lg:text-2xl">
                {dayjs(contract?.date_created).format('DD MMM, YYYY')}
              </p>
            </span>
          </div>
          <div className="h-24 flex items-center divide-x divide-solid w-full">
            <span className="w-1/2 flex flex-col items-center">
              <p className="text-xs md:text-md">Transaction Amount</p>
              <p className="font-black text-lg md:font-semibold lg:text-2xl">
                {(contractAmount | 0).toLocaleString()} UGX
              </p>
            </span>
            <span className="w-1/2 flex flex-col items-center">
              <p className="text-xs md:text-md">Fees</p>
              <p className="font-black text-lg md:font-semibold lg:text-2xl">
                {Number(contract?.charges).toLocaleString()} UGX
              </p>
            </span>
          </div>
          <div className="h-24 flex items-center divide-x divide-solid w-full">
            <span className="w-1/2 flex flex-col items-center">
              <p className="text-xs md:text-md">Transaction Period</p>
              <p className="font-black text-lg md:font-semibold lg:text-2xl">
                {/* {dayjs(contract?.due_date).diff(dayjs(), 'day')}{' '}
                {dayjs(contract?.due_date).diff(dayjs(), 'day') === 1 ? 'day' : 'days'} */}

                {/* Check if the contract is expired */}
                {isExpired ? (
                  // Display expiration message
                  <p className="font-black text-lg md:font-semibold lg:text-2xl">
                    It expired {-daysDifference} {Math.abs(daysDifference) === 1 ? 'day' : 'days'}{' '}
                    ago
                  </p>
                ) : (
                  // Display the number of days until due date
                  <p className="font-black text-lg md:font-semibold lg:text-2xl">
                    {daysDifference} {daysDifference === 1 ? 'day' : 'days'}
                  </p>
                )}
              </p>
            </span>
            <span className="w-1/2 flex flex-col items-center">
              <p className="text-xs md:text-md">Due Date</p>

              <div>
                <p className="font-black text-xs md:font-semibold md:text-base">
                  {/* Format due date as 'DD MMM, YYYY' */}
                  {dayjs(contract.due_date).format('DD MMM, YYYY')}
                </p>
                <p className="font-black text-gray-500 text-xs md:font-semibold lg:text-xs">
                  {/* Show relative time from now */}
                  <span>{dayjs(contract.due_date).fromNow()}</span>
                  {/* Show time of the due date */}
                  <span className="font-bold"> at {dayjs(contract.due_date).format('HH:mm')}</span>
                </p>
              </div>
            </span>
          </div>
          <div className="h-24 flex items-center divide-x divide-solid w-full">
            <span className="w-1/2 flex flex-col items-center">
              <p className="text-xs md:text-md">Payer</p>
              <p className="font-black text-lg md:font-semibold lg:text-2xl">{contract.sender}</p>
            </span>
            <span className="w-1/2 flex flex-col items-center">
              <p className="text-xs md:text-md">Receiver</p>
              <p className="font-black text-lg md:font-semibold lg:text-2xl">{contract.receiver}</p>
            </span>
          </div>
          <div className="h-24 flex items-center divide-x divide-solid w-full">
            <span className="w-full flex flex-col items-center px-8">
              <p className="text-xs md:text-md">Narration</p>
              <p className="font-black text-md md:font-semibold">{contract.reason || 'None'}</p>
            </span>
          </div>
          <ContractTransactions contract={contract} />
        </Box>
      )}
      <Tags tags={contract.tags} />
    </>
  );
};

export const ContractTransactions: React.FC<Props> = ({ contract }) => {
  return (
    <Box overflowX="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Amount</Th>
            <Th>Type</Th>
            <Th>Phone</Th>
            <Th>Status</Th>
            <Th>Channel</Th>
            <Th>Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr key={contract.uuid}>
            <Td>{Number(contract.final_amount).toLocaleString()}</Td>
            <Td>
              <LBadge dark={contract.kind === 'payout'}>{contract.kind}</LBadge>
            </Td>
            <Td>{contract.phone}</Td>
            <Td>
              <ContractStatus status={contract.status} />
            </Td>
            <Td>{contract.gateway_id}</Td>
            <Td>
              <Text width="5rem">
                <DateInfo date={contract.date_created} timeOff />
              </Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};
