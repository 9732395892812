/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRouteHistory } from 'app/contexts/router';
import { AppRoutes } from 'app/pages/routes';
import api from 'app/services/api';
import { publicRoutes } from 'app/services/constants';
import { getErrorMessage } from 'app/utils/objects';
import { Environment } from 'env';
import { makeRemoteAuthentication } from 'factory';
import LogRocket from 'logrocket';
import { handleError, handleSuccess } from '.';
import { useCookies } from '../cookies';
import { useProfiles } from './profile';
import { CreateUser } from 'domain/repositories';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuth = () => {
  const history = useHistory();
  const { removeCookie, setCookie, getCookie } = useCookies();
  const authInteractor = makeRemoteAuthentication();
  const { getProfile } = useProfiles();
  const routeHistory = useRouteHistory();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDefaultRouteByRole = (is_staff: boolean) =>
    is_staff ? AppRoutes.DASHBOARD : AppRoutes.NEW_PAYMENT;

  const redirectUser = (user?: any) => {
    const prevRoute = routeHistory.from || AppRoutes.HOME;

    const routeTo = publicRoutes.includes(prevRoute)
      ? getDefaultRouteByRole(user?.is_staff)
      : prevRoute;

    history.push(routeTo);
  };

  //signing up user
  const onSignUp = async (values: any) => {
    setLoading(true);

    try {
      const { data } = await api.post('/signup', values, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${Environment.API_TOKEN}`,
        },
      });
      console.log('the response is', data);

      if (data.status === '200') {
        handleSuccess('Registration is successful', 'Account Created');
        await onLogin({ username: values.username, password: values.password });
      } else {
        handleError(data.message);
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  //create user
  const onCreateUser = async (values: CreateUser) => {
    console.log('the data got in creating the user is: ', values);
    setLoading(true);
    try {
      const { data } = await api.post('/signup', values, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${Environment.API_TOKEN}`,
        },
      });
      console.log('the response is', data);

      if (data.status === '200') {
        history.push(AppRoutes.DASHBOARD);
        handleSuccess('User Account Created Successfully');
      } else {
        handleError(data.message);
      }
    } catch (error) {
      handleError(error);
      console.log('Error in regestering the user is', error);
    }
    setLoading(false);
  };

  //logging in user
  const onLogin = async ({ username, password }: any) => {
    setLoading(true);
    try {
      const { data, error } = await authInteractor.login({ username, password });
      if (data) {
        console.log('the response is', data);
        setCookie('session', data?.access_token);
        setCookie('loggedInUser', username);
        const user = await getProfile();

        user &&
          LogRocket.identify(user.uuid, {
            username: user.username,
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            is_staff: user.is_staff,
            env: process.env.NODE_ENV,
          });

        redirectUser(user);
      } else {
        handleError(error);
      }
    } catch (error: any) {
      handleError(error?.message, 'Login failed');
    }
    setLoading(false);
  };

  const onLogout = async () => {
    removeCookie('session');
    removeCookie('user');
    history.push(AppRoutes.HOME);
  };

  return {
    onSignUp,
    onLogin,
    onLogout,
    error,
    setError,
    loading,
    onCreateUser,
    tokens: getCookie('session'),
  };
};

export const useAuthRedirect = async () => {
  const { hash } = useLocation<any>();
  const history = useHistory();

  const params = new URLSearchParams(hash.substring(1));

  switch (params.get('type')) {
    case 'recovery':
    case 'signup':
    case 'invite':
      history.push(`${AppRoutes.CHANGE_PASSWORD}?${hash.substring(1)}`);
      break;
    default:
      break;
  }
};
