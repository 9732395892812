export const API = {
  development: 'http://localhost:8000',
  test: 'https://uat.limbopay.com',
  production: 'https://api.limbopay.com',
  app_token: 'LIMBOPAY_123',
};

export const Environment = {
  API_URL: API.production,
  API_TOKEN: API.app_token,
};
