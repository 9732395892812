import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  Avatar,
} from '@chakra-ui/react';
import { Feature } from 'app/components/controls';
import LogoutButton from 'app/components/logout';
import { BuildDetailsWidget } from 'app/components/widgets/build';
import { LButton } from 'app/components/widgets/buttons';
import { useAuth } from 'app/hooks/backend';
import { useSession } from 'app/hooks/backend/session';
import { LimboPayLogo } from 'app/images';
import { AppRoutes } from 'app/pages/routes';
import { adminMenus } from 'app/services/constants';
import { useStore } from 'app/state/store';
import React from 'react';
import { Link as ReactLink, useLocation } from 'react-router-dom';

export const NavBar = () => {
  const { onLogout } = useAuth();
  const { currentMenu } = useStore();
  const hidden = useBreakpointValue({ base: true, md: false });

  return (
    <nav className="flex items-center justify-between my-2 md:my-0">
      <Box className="flex items-center space-x-2">
        <AdminMobileNavBar />
        <Text color="light-blue.900" fontWeight="thin" fontSize={['xl', '2xl']}>
          {currentMenu?.name}
        </Text>
      </Box>
      <ul className="flex items-center flex-shrink-0 space-x-1">
        <Link
          _hover={{ underline: 'none', bg: 'black', color: 'white' }}
          hidden={!hidden}
          to={AppRoutes.USER_PROFILE}
          as={ReactLink}
          className="text-black inline-flex items-center w-full px-1 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-[#F3F6F9] hover:text-gray-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span className="ml-1" hidden={hidden}>
            Profile
          </span>
        </Link>
        <BuildDetailsWidget />
        <Link
          _hover={{ underline: 'none', bg: 'black', color: 'white' }}
          px="4"
          py="1"
          borderRadius="full"
          className="text-black inline-flex items-center w-full px-1 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-[#F3F6F9] hover:text-gray-800"
          onClick={onLogout}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
          </svg>
          <span className="ml-1" hidden={hidden}>
            Logout
          </span>
        </Link>
      </ul>
    </nav>
  );
};

const AdminMobileNavBar = () => {
  return (
    <div className="block md:hidden">
      <Menu>
        <MenuButton
          px="2"
          py="1"
          transition="all 0.2s"
          borderRadius="20px"
          borderWidth="1px"
          _hover={{ boxShadow: 'none', bg: 'gray.100' }}
          _expanded={{ bg: 'black', color: 'white' }}
          _focus={{ boxShadow: 'none', outline: 'none' }}>
          <HamburgerIcon />
        </MenuButton>
        <MenuList _hover={{ boxShadow: 'none', outline: 'none' }}>
          {adminMenus.map(menu => (
            <Link key={menu.key} _hover={{ outline: 'none' }} as={ReactLink} to={menu.key}>
              <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>{menu.name}</MenuItem>
            </Link>
          ))}
          <MenuDivider />
          <Box w="full" d="flex" justifyContent="space-between">
            <Link
              borderRadius="20px"
              _hover={{ outline: 'none', bg: 'black', color: 'white' }}
              _focus={{ outline: 'none' }}
              as={ReactLink}
              to={AppRoutes.USER_PROFILE}>
              <MenuItem>Profile</MenuItem>
            </Link>
            <LogoutButton />
          </Box>
        </MenuList>
      </Menu>
    </div>
  );
};

export const PublicNav = () => {
  const hidden = useBreakpointValue({ base: true, md: false });
  const { pathname } = useLocation();
  const { user } = useSession();
  const menus = [
    { name: 'Home', path: AppRoutes.HOME },
    { name: 'About', path: AppRoutes.ABOUT },
    { name: 'Arbitration', path: AppRoutes.ARBITRATION },
    { name: 'Contact Us', path: AppRoutes.CONTACT },
  ];
  const { tokens } = useAuth();

  return (
    <Box bg="white" px={['4', '56']} py="2" className="flex items-center justify-between">
      <Box className="flex items-start space-x-2">
        <LimboPayLogo size={hidden ? '50px' : '80px'} />
      </Box>
      <Box hidden={hidden} className="flex items-center flex-shrink-0 space-x-8 text-md">
        {menus.map(menu => (
          <Link
            key={menu.path}
            to={menu.path}
            as={ReactLink}
            pb="2"
            _hover={{
              underline: 'none',
              fontWeight: 'semibold',
              borderBottom: '1px solid black',
            }}
            fontWeight={pathname === menu.path ? 'black' : 'normal'}
            _focus={{
              outline: 'none',
            }}
            borderBottom={menu.path === pathname ? '2px solid black' : 'none'}
            color={menu.path === pathname ? 'black' : 'inherit'}>
            {menu.name}
          </Link>
        ))}
      </Box>
      <MobileNavBar />
      <ul className="hidden md:flex items-center flex-shrink-0 space-x-1">
        <li className="hidden md:flex space-x-4">
          <Feature on={!tokens}>
            <Link as={ReactLink} to={AppRoutes.LOGIN} _focus={{ outline: 'none' }}>
              <Button as={LButton}>Login</Button>
            </Link>
            <Link
              as={ReactLink}
              to={AppRoutes.SIGNUP}
              _hover={{ outline: 'none' }}
              _focus={{ outline: 'none' }}>
              <LButton dark>Signup</LButton>
            </Link>
          </Feature>
          <Feature on={!!user && !!tokens}>
            <Menu>
              <MenuButton
                px="2"
                py="1"
                transition="all 0.2s"
                borderRadius="full"
                borderWidth="1px"
                _hover={{ boxShadow: 'none', bg: 'gray.100' }}
                _expanded={{ bg: 'black', color: 'white' }}
                _focus={{ boxShadow: 'none', outline: 'none' }}>
                <div className=" flex items-center">
                  <Avatar src={user?.profile_pic} size="sm" />
                  <span className="ml-1">
                    {`${user?.first_name} ${user?.last_name}` || user?.username}
                  </span>
                  <ChevronDownIcon ml="2" />
                </div>
              </MenuButton>
              <MenuList _hover={{ boxShadow: 'none', outline: 'none' }}>
                <Link _hover={{ outline: 'none' }} as={ReactLink} to={AppRoutes.USER_PAYMENTS}>
                  <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>Payments</MenuItem>
                </Link>
                <Feature
                  permissions={{
                    allowed: [{ obj: user, attribute: 'is_staff', value: true }],
                    rejected: [],
                  }}>
                  <Link _hover={{ outline: 'none' }} as={ReactLink} to={AppRoutes.DASHBOARD}>
                    <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>
                      Admin Portal
                    </MenuItem>
                  </Link>
                </Feature>
                <MenuDivider />
                <Link _hover={{ outline: 'none' }} as={ReactLink} to={AppRoutes.USER_PROFILE}>
                  <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>Profile</MenuItem>
                </Link>

                <Link _hover={{ outline: 'none' }} as={ReactLink} to={AppRoutes.CHANGE_PASSWORD}>
                  <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>
                    Change Password
                  </MenuItem>
                </Link>
              </MenuList>
            </Menu>
            <LogoutButton />
          </Feature>
        </li>
      </ul>
    </Box>
  );
};

export const MobileNavBar = () => {
  const { user } = useSession();
  const { tokens } = useAuth();
  return (
    <div className="block md:hidden">
      <Menu>
        <MenuButton
          px="2"
          py="1"
          transition="all 0.2s"
          borderRadius="20px"
          borderWidth="1px"
          _hover={{ boxShadow: 'none', bg: 'gray.100' }}
          _expanded={{ bg: 'black', color: 'white' }}
          _focus={{ boxShadow: 'none', outline: 'none' }}>
          <HamburgerIcon />
        </MenuButton>
        <MenuList _hover={{ boxShadow: 'none', outline: 'none' }}>
          <Link _hover={{ outline: 'none' }} as={ReactLink} to={AppRoutes.HOME}>
            <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>Home</MenuItem>
          </Link>
          <Link _hover={{ outline: 'none' }} as={ReactLink} to={AppRoutes.ABOUT}>
            <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>About</MenuItem>
          </Link>
          <Link _hover={{ outline: 'none' }} as={ReactLink} to={AppRoutes.ARBITRATION}>
            <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>Arbitration</MenuItem>
          </Link>
          <Link _hover={{ outline: 'none' }} as={ReactLink} to={AppRoutes.CONTACT}>
            <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>Contact Us</MenuItem>
          </Link>
          <MenuDivider />
          <Feature on={!tokens}>
            <MenuItem>
              <HStack spacing={2}>
                <Link as={ReactLink} to={AppRoutes.LOGIN} _focus={{ outline: 'none' }}>
                  <Button as={LButton} btnSize="sm">
                    Login
                  </Button>
                </Link>
                <Link
                  as={ReactLink}
                  to={AppRoutes.SIGNUP}
                  _hover={{ outline: 'none' }}
                  _focus={{ outline: 'none' }}>
                  <LButton btnSize="sm" dark>
                    Signup
                  </LButton>
                </Link>
              </HStack>
            </MenuItem>
          </Feature>
          <Feature
            permissions={{
              allowed: [{ obj: user, attribute: 'is_staff', value: true }],
              rejected: [],
            }}>
            <Link _hover={{ outline: 'none' }} as={ReactLink} to={AppRoutes.DASHBOARD}>
              <MenuItem _hover={{ boxShadow: 'none', outline: 'none' }}>Admin Portal</MenuItem>
            </Link>
          </Feature>
          <Feature on={!!user && !!tokens}>
            {/* <MenuItem> */}
            <Box w="full" d="flex" justifyContent="space-between" alignItems="center" px="2">
              <Link
                borderRadius="20px"
                px="2"
                py="1"
                _hover={{ outline: 'none', bg: 'black', color: 'white' }}
                _focus={{ outline: 'none' }}
                as={ReactLink}
                to={AppRoutes.USER_PROFILE}>
                Account
              </Link>
              <Link
                borderRadius="20px"
                px="2"
                py="1"
                _hover={{ outline: 'none', bg: 'black', color: 'white' }}
                _focus={{ outline: 'none' }}
                as={ReactLink}
                to={AppRoutes.CHANGE_PASSWORD}>
                Change Password
              </Link>
              <LogoutButton />
            </Box>
            {/* </MenuItem> */}
          </Feature>
        </MenuList>
      </Menu>
    </div>
  );
};
