import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Stack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Loading } from '../controls/loading';
import { getStatusColor, ContractStatus, LBadge } from '../controls/badges';
import Pagination from '../controls/pagination';

import { PaymentDetails } from '../widgets/payment-detail';
import { LButton } from '../widgets/buttons';
import { DateInfo, LimboUser } from '../widgets/misc';
import { Feature } from '../controls';
import useTransactionStore from 'app/state/store/transactions';
import { Arbitration, PaymentCreateParams } from 'domain/repositories/payment';

const ViewPaymentModal = ({ isOpen, onClose, payment, arbitration }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="3xl" motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>View Details</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <PaymentDetails payment={arbitration} transaction={payment} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

type Props = {
  isMobile: boolean | undefined;
  allArbitrations: Arbitration[];
  transactions: PaymentCreateParams[];
};
export const PaymentsTable = ({ isMobile, allArbitrations, transactions }: Props) => {
  const [page, setPage] = useState(1);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedArbitration, setSelectedArbitration] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const { loading } = useTransactionStore();

  const openViewPaymentModel = (item: any, transaction: any) => {
    setSelectedArbitration(item);
    setSelectedPayment(transaction);
    onOpen();
  };

  if (loading) return <Loading />;

  const list = allArbitrations;

  // Calculate pagination
  const limit = 10;
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedArbitrations = list.slice(startIndex, endIndex);
  const total: number = list?.length || 0;

  return (
    <>
      <Feature on={isMobile}>
        <Box className="lg:hidden flex flex-col space-y-2 text-sm">
          {paginatedArbitrations?.map((item: Arbitration) => {
            const transaction = transactions.find(
              transaction => transaction.uuid === item.transaction_id
            );
            const finalAmount = transaction?.final_amount;
            return (
              <Box
                key={`mobile-${item.id}`}
                p={2}
                borderLeftColor={`${getStatusColor(item.status?.toLowerCase())}.500`}
                borderLeftWidth="4px"
                rounded="md"
                bg={`${getStatusColor(item.status?.toLowerCase())}.100`}
                onClick={() => openViewPaymentModel(item, transaction)}>
                <Box className="flex justify-between border-b-0">
                  <div className="flex flex-col max-w-1/2">
                    <span className="font-black">
                      {dayjs(item.created_at).format('DD MMM, YYYY')}
                    </span>
                    <i className="font-semibold text-gray-600">{item.tran_type}</i>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-black text-right">
                      {Number(finalAmount).toLocaleString()}
                    </span>
                    <i className="font-black text-gray-600 text-right text-sm flex flex-end">
                      {item.respondent}
                    </i>
                  </div>
                </Box>
                <Text mt="1" fontSize="0.75rem">
                  {item.message}
                </Text>
              </Box>
            );
          })}
          <span className="mb-4" />
        </Box>
      </Feature>

      {/* a big table on laptop */}
      <Feature on={!isMobile}>
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Amount</Th>
                <Th>Created</Th>
                <Th>Respondent</Th>
                <Th>Status</Th>
                <Th>Channel</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedArbitrations?.map((item: Arbitration) => {
                const transaction = transactions.find(
                  transaction => transaction.uuid === item.transaction_id
                );
                const finalAmount = transaction?.final_amount;

                return (
                  <Tr key={item.id}>
                    <Td>{item.id}</Td>
                    <Td>{finalAmount}</Td>

                    <Td>
                      <LimboUser username={item.created_by} />
                    </Td>
                    <Td>
                      <LimboUser username={item.respondent} />
                    </Td>
                    <Td>
                      <ContractStatus status={item.status} />
                    </Td>
                    <Td>{transaction?.gateway_id}</Td>
                    <Td>
                      <Text width="6rem">
                        <DateInfo date={item.created_at} />
                      </Text>
                    </Td>
                    <Td>
                      <Stack direction="row" alignItems="center">
                        <Button
                          as={LButton}
                          dark
                          onClick={() => openViewPaymentModel(item, transaction)}
                          btnSize="xs"
                          colorScheme="blue-gray">
                          View
                        </Button>
                      </Stack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Feature>

      <Pagination page={page} total={total} limit={limit} setPage={setPage} />
      <ViewPaymentModal
        isOpen={isOpen}
        onClose={onClose}
        payment={selectedPayment}
        arbitration={selectedArbitration}
      />
    </>
  );
};
