/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { useRouteHistory } from 'app/contexts/router';
import { currentUserState } from 'app/state/atoms';
import { SendCodeParams, VerifyCodeParams } from 'domain/repositories/user';
import { makeUserApi } from 'factory/interactors';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { handleError, notify } from '.';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useProfiles = () => {
  const history = useHistory();
  const routeHistory = useRouteHistory();

  const userInteractor = makeUserApi();
  const { setCurrentUser, getCurrentUser } = useRecoilValue(currentUserState);
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  const getProfile = async () => {
    setLoading(true);
    const { data, error } = await userInteractor.getProfile();

    console.log('data of the user', data);
    if (data) {
      const userProfile = data;
      setCurrentUser(userProfile);
      setLoading(false);
      return userProfile;
    } else {
      setLoading(false);
      notify('error', error, 'Profile Error');
    }
  };

  const updateProfile = async ({ values }: any) => {
    setLoading(true);
    const { data, error } = await userInteractor.updateProfile(values);
    if (data) {
      const userProfile = data;
      setCurrentUser(userProfile);
      notify('success', 'Profile updated successfully');

      setLoading(false);
      return data;
    } else {
      setLoading(false);
      notify('error', error, 'Error updating profile');
    }
  };

  const sendVerifyCode = async (channel: SendCodeParams) => {
    setLoading(true);
    const { error } = await userInteractor.sendVerifyCode({ channel: channel.channel });
    handleError(error);
    setLoading(false);
  };

  const onVerifyCode = async (code: VerifyCodeParams) => {
    setLoading(true);
    const { data } = await userInteractor.verifyCode(code);

    if (data.is_verified) {
      notify('success', 'Account verified successfully');
      const redirect = routeHistory.to as string;
      await getProfile();
      history.push(redirect);
    } else {
      handleError(data.message);
      return;
    }
    setLoading(false);
  };

  return {
    error,
    updateProfile,
    setError,
    loading,
    getProfile,
    profile: getCurrentUser(),
    sendVerifyCode,
    onVerifyCode,
  };
};
