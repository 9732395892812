import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import * as React from 'react';
import { useState } from 'react';
import ReactJson from 'react-json-view';
import { Feature } from '../controls';
import { ContractStatus } from '../controls/badges';
import { LButton } from './buttons';
import { Card, CardContent, CardHeader, Property } from './cards';
import { Arbitration, PaymentCreateParams } from 'domain/repositories/payment';
import useArbitrationStore from 'app/state/store/arbitration';

type Props = {
  payment: Arbitration | null;
  transaction: PaymentCreateParams | null;
};
export const PaymentDetails = ({ payment, transaction }: Props) => {
  const [showMetadata, setShowMetadata] = useState(false);
  const changeView = () => setShowMetadata(!showMetadata);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { cancelArbitration } = useArbitrationStore();

  return (
    <Card>
      <CardHeader title={<ContractStatus status={payment?.status} />}>
        <Feature permissions={{ allowed: [{ attribute: 'is_staff', value: true }], rejected: [] }}>
          <Button
            as={LButton}
            type="button"
            btnSize="sm"
            colorScheme="blue-gray"
            onClick={changeView}>
            {showMetadata ? 'Details' : 'Metadata'}
          </Button>
        </Feature>
        <Feature
          permissions={{ rejected: [{ attribute: 'status', value: 'cancelled', obj: payment }] }}>
          <Button
            as={LButton}
            dark
            btnSize="sm"
            _focus={{ boxShadow: 'none', outline: 'none' }}
            onClick={onOpen}
            size="sm"
            colorScheme="red">
            Finish dispute
          </Button>
        </Feature>
      </CardHeader>

      {/* REASON MODEL */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Finishing Dispute</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to end this Arbitration dispute?</Text>
          </ModalBody>

          <ModalFooter>
            <Button as={LButton} onClick={onClose}>
              Close
            </Button>
            <Button as={LButton} dark onClick={() => cancelArbitration(payment?.id)}>
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <CardContent>
        {showMetadata ? (
          <Box my="6">
            <div className="flex items-center w-full">
              <ReactJson src={transaction?.meta} />
            </div>
          </Box>
        ) : (
          <>
            <Property label="UUID" value={payment?.id} />
            <Property label="Phone" value={payment?.created_by} />
            <Property label="Sender" value={transaction?.sender} />
            <Property label="Reciever" value={transaction?.receiver} />
            <Property label="Amount" value={Number(transaction?.final_amount).toLocaleString()} />
            <Feature
              permissions={{
                allowed: [{ obj: transaction?.meta, attribute: 'disbursement_group' }],
                rejected: [],
              }}>
              <Property
                label="Category"
                value={`${transaction?.category} | ${transaction?.meta?.disbursement_group ?? ''}`}
              />
            </Feature>
            <Feature
              permissions={{ allowed: [{ attribute: 'is_staff', value: true }], rejected: [] }}>
              <Property label="Gateway Request ID" value={transaction?.gateway_id} />
              <Property label="Gateway Response ID" value={transaction?.pay_type} />
            </Feature>
            <Property label="Reason" value={payment?.message} />
            <Property
              label="Date"
              value={dayjs(payment?.created_at).format('DD MMM, YYYY HH:mm:ss')}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};
