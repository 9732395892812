import { HttpClient } from 'data/protocols/http';

import { ApiResult } from './models/api-result';
import { receiveResponse } from './utils';
import {
  Arbitration,
  ArbitrationRepository,
  RaiseArbitration,
} from 'domain/repositories/arbitration';
import { Environment } from 'env';

export class ArbitrationApi implements ArbitrationRepository {
  arbitrations: Arbitration[] = [];
  allArbitrations: Arbitration[] = [];
  loading: boolean = false;
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ApiResult>) {}

  //getting all arbitrations
  async getArbitrations() {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }

  //getting all arbitrations
  async getAllArbitrations() {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/all`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }
  //cancel arbitration
  async cancelArbitration(params?: string) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/cancel_arbitration/${params}`,
      method: 'post',
    });
    return receiveResponse(httpResponse);
  }
  //raise arbitration
  async raiseArbitration(params: RaiseArbitration) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/arbitrations`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }
}
