import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  Select,
  Stack,
  Tooltip,
  useDisclosure,
  RadioGroup,
  Collapse,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../utils';
import dayjs from 'dayjs';
import cuid from 'cuid';
import { useSession } from 'app/hooks/backend/session';
import { useContracts } from 'app/hooks/backend/contracts';
import { LButton } from '../../widgets/buttons';
import { Feature } from '../../controls';
import { QuestionIcon } from '@chakra-ui/icons';
import { Regex } from 'app/utils/regex';
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

interface FormData {
  amount: number;
  narration: string;
  period: string;
}

const periods = [
  { key: '1_DAY', name: '1 day', rate: 2, days: 1 },
  { key: '3_DAY', name: '3 days', rate: 4, days: 3 },
  { key: '1_WEEK', name: '1 Week', rate: 6, days: 7 },
  { key: '2_WEEKS', name: '2 Weeks', rate: 8, days: 14 },
  { key: '4_WEEK', name: '4 Weeks', rate: 10, days: 28 },
  { key: '6_WEEK', name: '6 Weeks', rate: 15, days: 42 },
];

const contractTypeOptions: any = {
  payment: {
    field: 'receiver',
    label: 'Payee',
    message: 'The phone number of the person you are paying',
  },
  request: {
    field: 'payer',
    label: 'Payer',
    message: 'The phone number of the person you are requesting to pay',
  },
};

interface CreateContractFormProps {
  onComplete?: Function;
  initialContractType?: string;
}

export const CreateContractForm = ({
  onComplete,
  initialContractType,
}: CreateContractFormProps) => {
  const [contractType, setContractType] = useState<any>(initialContractType);
  const [contractTypeDetails, setContractTypeDetails] = useState<any>({});

  const { isOpen, onOpen } = useDisclosure();
  const { user } = useSession();
  const { createContract, loading } = useContracts();
  const [details, setDetails] = useState({
    rate: 0,
    interest: 0,
    next_date: new Date().toLocaleDateString(),
    days: 0,
  });
  const { handleSubmit, register, errors, getValues, reset: resetForm } = useForm<FormData>();

  useFormErrors(errors);

  useEffect(() => {
    if (initialContractType) onOpen();
  }, [initialContractType, onOpen]);

  useEffect(() => {
    if (contractType) {
      const option = contractTypeOptions[contractType];
      setContractTypeDetails(option);
    }
  }, [contractType]);

  const onSubmit = handleSubmit(async values => {
    const period = periods.find(p => p.key === values.period);

    const data = {
      ...values,
      reference: `limbo-${cuid.slug()}`,
      period: period?.days,
      tags: ['web'],
      user_id: user.uuid,
    };
    if (contractType === 'request') {
      Object.assign(data, { receiver: user.username, initiator: 'receiver' });
    } else {
      Object.assign(data, { payer: user.username, initiator: 'payer' });
    }

    await createContract(data);
    resetForm();
    onComplete && (await onComplete());
  });

  const handlePeriodChange = (e: { target: { value: any } }) => {
    const period = periods.find(p => p.key === e.target.value);
    const amount = getValues('amount');

    if (period) {
      const interest = amount * (period.rate / 100);
      setDetails({ ...details, interest, rate: period.rate, days: period.days });
    }
  };

  const handleAmountChange = (e: { target: { value: string } }) => {
    const selectedPeriod = getValues('period');
    const period = periods.find(p => p.key === selectedPeriod);
    const amount = Number(e.target.value);

    if (period) {
      const interest = amount * (period.rate / 100);
      setDetails({ ...details, interest, rate: period.rate, days: period.days });
    }
  };

  const onChangeContractType = (value: string): void => {
    setContractType(value);
    onOpen();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <RadioGroup mb="6" onChange={onChangeContractType} value={contractType}>
          <Stack spacing={2}>
            <Radio colorScheme="orange" value="payment">
              Are you{' '}
              <span className={contractType === 'payment' ? 'font-bold italic' : 'font-normal'}>
                paying
              </span>{' '}
              for a service?
            </Radio>
            <Radio colorScheme="orange" value="request">
              Are you{' '}
              <span className={contractType === 'request' ? 'font-bold italic' : 'font-normal'}>
                requesting
              </span>{' '}
              to be paid for a service?
            </Radio>
          </Stack>
        </RadioGroup>
        <Collapse in={isOpen} animateOpacity>
          <Stack spacing="6" mb="6">
            <HStack spacing="4">
              <FormControl>
                <FormLabel>Amount</FormLabel>
                <Input
                  name="amount"
                  type="number"
                  onChange={handleAmountChange}
                  focusBorderColor="black"
                  required
                  ref={register({
                    min: { value: 500, message: 'Amount cannot be less than 500' },
                    max: { value: 10000000, message: 'Amount cannot be greater than 10m' },
                  })}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Period</FormLabel>
                <Select
                  focusBorderColor="black"
                  name="period"
                  type="text"
                  required
                  ref={register}
                  onChange={handlePeriodChange}>
                  {periods.map(period => (
                    <option key={period.key} value={period.key}>
                      {period.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </HStack>
            <FormControl>
              <FormLabel className="flex items-center space-x-2">
                <span>{contractTypeDetails?.label}</span>
                <Tooltip label={contractTypeDetails?.message}>
                  <QuestionIcon />
                </Tooltip>
              </FormLabel>
              <Input
                focusBorderColor="black"
                name={contractTypeDetails?.field ?? 'payee'}
                type="text"
                required
                ref={register({
                  pattern: {
                    value: Regex.VALID_PHONE_NUMBER,
                    message: 'Please enter a valid phone number',
                  },
                })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Purpose</FormLabel>
              <Input
                focusBorderColor="black"
                name="narration"
                type="text"
                required
                ref={register}
              />
            </FormControl>

            <Feature on={false}>
              <Box className="flex flex-col mx-0">
                <p className="text-gray-500">Your total payment will be</p>
                <span className="flex my-8">
                  <sup>UGX</sup>
                  <h4 className="font-black text-xl md:text-4xl">
                    {(details.interest + Number(getValues('amount'))).toLocaleString()}
                  </h4>
                </span>
                <div className="flex space-x-8 lg:space-x-16">
                  <Feature on={false}>
                    <p className="text-gray-500 mb-2">Rate</p>
                    <p className="font-black text-lg md:font-semibold lg:text-2xl">
                      {details.rate}%
                    </p>
                  </Feature>
                  <span>
                    <p className="text-gray-500 mb-2">Due Date</p>
                    <p className="font-black text-lg md:font-semibold lg:text-2xl">
                      {dayjs().add(details.days, 'day').format('DD MMM, YYYY HH:mm')}
                    </p>
                  </span>
                </div>
              </Box>
            </Feature>
            <Button type="submit" as={LButton} dark size="md" fontSize="md" isLoading={loading}>
              Submit
            </Button>
          </Stack>
        </Collapse>
      </form>
    </>
  );
};
