import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Loading } from '../controls/loading';
import Pagination from '../controls/pagination';

import { Feature } from '../controls';
import { Copier, DateInfo, LimboUser } from '../widgets/misc';
import useAuthStore from 'app/state/store/auth';
import { UserProfile } from 'domain/repositories';
import { UserDetails } from '../widgets/UserDetails';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  user: UserProfile | null;
};
const ViewPaymentModal = ({ isOpen, onClose, user }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} size="3xl" motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>View Details</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <UserDetails user={user} />
      </ModalBody>
    </ModalContent>
  </Modal>
);
interface Propss {
  users: UserProfile[];
  loading: boolean;
}
export const UsersTable = ({ users, loading }: Propss) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [page, setPage] = useState(1);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState(null);

  const openViewPaymentModel = (item: any) => {
    setSelectedUser(item);
    onOpen();
  };

  let filteredUsers = users;
  // Calculate pagination
  const limit = 10; // Number of transactions per page
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedUsers = filteredUsers.slice(startIndex, endIndex);

  const total = filteredUsers?.length || 0;
  if (loading) return <Loading />;

  return (
    <>
      <Feature on={isMobile}>
        <Box className="lg:hidden flex flex-col space-y-2 text-sm">
          {paginatedUsers?.map((item: UserProfile) => (
            <Box
              key={`mobile-${item.uuid}`}
              p={2}
              borderLeftWidth="4px"
              rounded="md"
              onClick={() => openViewPaymentModel(item)}>
              <Box className="flex justify-between border-b-0">
                <div className="flex flex-col max-w-1/2">
                  <span className="font-black">
                    {dayjs(item.date_created).format('DD MMM, YYYY')}
                  </span>
                  <i className="font-semibold text-gray-600">
                    {item.first_name + ' ' + item.last_name}
                  </i>
                </div>
                <div className="flex flex-col">
                  <i className="font-black text-gray-600 text-right text-sm flex flex-end">
                    {item.username}
                  </i>
                </div>
              </Box>
              <Text mt="1" fontSize="0.75rem">
                {item.email}
              </Text>
            </Box>
          ))}
          <span className="mb-4" />
        </Box>
      </Feature>
      <Feature on={!isMobile}>
        <Box overflowX="auto">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>User ID</Th>
                <Th>Name</Th>
                <Th>Username</Th>
                <Th>Email</Th>
                <Th>Created On</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedUsers?.map((item: UserProfile) => (
                <Tr key={item.uuid}>
                  <Td>
                    <Copier>{item.uuid}</Copier>
                  </Td>
                  <Td>
                    <LimboUser username={item.username} />
                  </Td>
                  <Td>{item.username}</Td>
                  <Td>{item.email}</Td>

                  <Td>
                    <Text width="6rem">
                      <DateInfo date={item.date_created} />
                    </Text>
                  </Td>
                  <Td>
                    <Button
                      onClick={() => openViewPaymentModel(item)}
                      size="xs"
                      colorScheme="blue-gray">
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Feature>

      <Pagination page={page} total={total} limit={limit} setPage={setPage} />
      <ViewPaymentModal isOpen={isOpen} onClose={onClose} user={selectedUser} />
    </>
  );
};
