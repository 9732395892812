export const Regex = {
  VALID_PHONE_NUMBER: /^(256)?[3,7][0,1,5,7,8,9]\d{7}$/i,
  VALID_INTL_NUMBER: /^(256)[3,7][0,1,5,7,8,9]\d{7}$/i,
  // VALID_VERIFICATION_CODE: /^([A-Z]){2}\w?\d?\d{2}$/i,
  VALID_VERIFICATION_CODE: /^\d{4}$/,
};

export const getPhoneNumber = (value: any) => {
  if (Regex.VALID_INTL_NUMBER.test(value)) {
    return value.slice(3);
  }
  if (Regex.VALID_PHONE_NUMBER.test(value)) {
    return `256${value}`;
  }
  return value;
};

export const getPaymentGateway = (phone: string, pay_type: string) => {
  if (pay_type === 'mobilemoney') {
    const phoneSlice = phone?.slice(0, 5);
    const gateway = ['25677', '25678'].includes(phoneSlice) ? 'mtn' : 'airtel';
    return gateway;
  }
  return 'flutterwave';
};
